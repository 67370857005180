import React, { useRef, useEffect } from 'react';
import { Card } from "primereact/card";
import { TaskList } from "./wf/TaskList";
import { DashboardCards } from './DashboardCards';
import axios from "axios";
import { oggettoJson } from './oggettoJson';




export const Dashboard = () => {
    const table = useRef();

    useEffect(() => {
        /*const oggettJson = oggettoJson;
        console.log('oggettoJSON >> ' + JSON.stringify(oggettJson));
        console.log("prova >>>> " + JSON.stringify(oggettJson["foto"]))
        axios.post("api/pratica/2922d01c-61a8-11ec-bd60-00090ffe0001/61c09ee5bcb2a64554ac7f73/immagine-geo", oggettJson["foto"])*/
    }, []);

    function update(value){
        table.current.reload();
    }
    


    return (
        <div className="p-grid layout-dashboard">
        <DashboardCards innerRef={table} />
            <div className="p-col-12">
                <Card title="TaskList" header={(<div className="card-header"></div>)}>
                    <hr />
                    <TaskList modifica={(value) => update(value)}/>
                    <hr />
                </Card>
            </div>
        </div>
    )

}
