import {DateText} from "../generic/DateText";
import { Button } from "primereact/button";
import axios from "axios";

let base = {
    CREAZIONE: {
        status: "Creazione Pratica",
        icon: "folder-plus",
        color: "#86af49"
    }, // creazione nuova pratica
    ACCETTAZIONE: {
        status: "Accettazione Pratica",
        icon: "check-circle",
        color: "#86af49"
    }, // Pratica accerttata
    RIFIUTO: {
        status: "Rifiuto Pratica",
        icon: "times-circle",
        color: "#c94c4c"
    }, // pratica Rifiutata
    CHIUSURA: {
        status: "Chiusura Pratica",
        icon: "folder-minus",
        color: "#6b5b95"
    }, // pratica chiusa
    RIASSEGNATA: {
        status: "Riassegnazione Pratica",
        icon: "sync-alt",
        color: "#b9936c"
    }, // pratica Riassegnata
    AGGIUTA_NOTA: {
        status: "Aggiunta nota alla Pratica",
        icon: "comment-dots",
        color: "#034f84"
    },
    AGGIUTA_FILE: {
        status: "Aggiunti files alla Pratica",
        icon: "paperclip",
        color: "#034f84"
    },
    ASSEGNAZIONE_SQUADRA: {
        status: "Squadra Assegnata",
        icon: "users",
        color: "orange"
    },
    FINE_LAVORAZIONE: {
        status: "Lavorazione Terminata",
        icon: "check-circle"
    },
    CONFERMATA: {
        status: "Pratica Chiusa",
        icon:"power-off"
    },
    NON_CONFERMATA: {
        status: "Pratica Non Confermata",
        icon: "stop-circle",
        color: "#c94c4c"
    },
    RIFIUTO_LAVORAZIONE: {
        status: "Rifiuto Lavorazione",
        icon: "times-circle",
        color: "#c94c4c"
    },
    ACCETTAZIONE_LAVORAZIONE: {
        status: "Accettazione Lavorazione",
        icon: "briefcase",
        color: "#964b00"
    },
    NON_CONFERMATA_CONTRACTOR: {
        status: "Pratica Non Confermata",
        icon: "stop-circle",
        color: "#c94c4c"
    },
    CONFERMATA_CONTRACTOR: {
        status: "Pratica Confermata",
        icon: "check-circle"
    }
}

const downloadFile = (url) => {
    axios.get(url)
    .then(response => {
        var arrBuffer = base64ToArrayBuffer2(response.data);
        const url = window.URL.createObjectURL(new Blob([arrBuffer]), { type: response.headers["content-type"] });
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['content-disposition'].split('attachment; filename=')[1]);
        document.body.appendChild(link);
        link.click();
    });
}

function base64ToArrayBuffer2(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

const convertHistoryItem = (historyItem,index) => {

    let event = {...base[historyItem.action]};
    event.key=index;
    event.subtitle = <>{historyItem.actionUserName} <DateText value={historyItem.actionDate} /></>

    if(historyItem.action === "AGGIUTA_NOTA"){
    event.meta = <div className="p-mt-2"> <b>{historyItem.metadata.files}</b></div>
    }

    if(historyItem.action === "AGGIUTA_FILE"){
        event.meta =
            (<>
            {historyItem.metadata.files.map((f)=>{
                return <>
                    <Button onClick={() => downloadFile("/api/file/download/"+f.id)} label={f.filename} className="p-button-link" />

                </>
            })}
            </>)


    }else  if(historyItem.action === "RIFIUTO"){
      event.meta = <div className="p-mt-2">Motivazione rifiuto:<br/> <b>{historyItem.metadata.motivazione_rifiuto}</b></div>
    } else  if(historyItem.action === "RIASSEGNATA"){

      event.meta = <>
          <div className="p-mt-2">Azienda:<br/> <b>{historyItem.metadata.azienda_assegnazione.denominazione}</b></div>
          {historyItem.metadata.note && <div className="p-mt-2">Note:<br/> <b>{historyItem.metadata.note}</b></div>}
      </>

    } else  if(historyItem.action === "ASSEGNAZIONE_SQUADRA"){
        event.meta = <>
            <div className="p-mt-2">Squadra:<br/> <b>{historyItem.metadata.squadra_assegnazione}</b></div>

        </>
  
      }else  if(historyItem.action === "NON_CONFERMATA"){
        event.meta = <div className="p-mt-2">Motivazione mancata conferma:<br/> <b>{historyItem.metadata.motivazione_rifiuto_finale}</b></div>
    } else if(historyItem.action === "RIFIUTO_LAVORAZIONE"){
        event.meta = <div className="p-mt-2">Motivazione rifiuto:<br/> <b>{historyItem.metadata.motivazione_rifiuto_squadra}</b></div>
    }else  if(historyItem.action === "NON_CONFERMATA_CONTRACTOR"){
        event.meta = <div className="p-mt-2">Motivazione mancata conferma:<br/> <b>{historyItem.metadata.motivazione_rifiuto_conferma}</b></div>
    }






    // inserire qui gestione metadati.
    return event;
};
export default convertHistoryItem;