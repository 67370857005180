
import * as PropTypes from "prop-types";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Icon = (props) => {

    return (
        <FontAwesomeIcon icon={props.icon} style={{color: props.iconColor}} size="2x"  />
    )
}
Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    iconColor: PropTypes.string,

};
Icon.defaultProps = {
    iconColor : "#0f97c7",
}

