import React, {useContext, useEffect, useState} from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import axios from "axios";
import PropTypes from "prop-types";
import {classNames} from "primereact/utils";
import {FormContext, FormItemContext} from "../../hooks/FormContext";
import {CondionalControllerWrapper} from "../../utils/CondionalControllerWrapper";


export const AjaxAutocomplete = (props) => {
    const {control} = useContext(FormContext) || {control: {}};
    return (
        <CondionalControllerWrapper name={props.name} wrap={props.withController} control={control}>
            <FormItemContext.Consumer>
                {field => <AjaxAutocompleteInner {...props} />}
            </FormItemContext.Consumer>
        </CondionalControllerWrapper>
    )
};
const AjaxAutocompleteInner = (props) => {
    const initValue = null;
    const [value, setValue] = useState(initValue);
    const {errors} = useContext(FormContext)  || {register: ()=>{},control: {},errors: {}};
    const field = useContext(FormItemContext) || {field:{}}
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);

    const applyValue = (newVal) => {

        if(newVal !== null || newVal !== undefined){
            setValue(newVal)
            return newVal;
        }else{
            setValue(initValue);
            return initValue;
        }
    }
    const search =  (event) => {
        if(event && event.query.trim().length > 0){
            let url = props.baseEndpoint + event.query;
            axios.get(url , {
                params : {
                    page:0,
                    size:props.maxResults
                }
            }).then((res)=>{
                setFilteredSuggestions(res.data);
            }).catch((err)=>{ setFilteredSuggestions([]);})
        }
    }
    const select = (e) => {
        let newVal = applyValue(e.value);
        if (props.withController) {
            field.onChange(newVal);
        }
        props.onChange(newVal);
    }
    const change = (e) => {
        let newVal = applyValue(e.value);

        if (props.withController) {
            field.onChange(newVal);
        }
        props.onChange(newVal);
    }

    useEffect(() => {

        if(props.withController){
            applyValue(field.value);
        }else{
            applyValue(props.value);
        }


    },[props.value, field.value]);




    return (


            <div className="p-field">
                <label htmlFor={props.name} className={classNames("p-d-block",{ 'p-error': errors[props.name] })}>{props.label}</label>
                <AutoComplete
                    style={{display:"grid"}}
                    panelStyle={{maxWidth:"100px"}}
                    placeholder={props.placeholder}
                    value={value}
                    delay={300}
                    inputClassName={classNames({ 'p-invalid': errors[props.name] })}
                    suggestions={filteredSuggestions}
                    completeMethod={search}
                    name={props.name}
                    field={props.nameField}
                    forceSelection

                    onSelect={select}
                    onChange={change}
                    itemTemplate={props.itemTemplate}


                />
                <small className="p-error" style={{display:"block"}}>{errors[props.name]?.message}</small>
            </div>
    )
}



AjaxAutocomplete.propTypes = {
    baseEndpoint: PropTypes.string.isRequired,
    itemTemplate: PropTypes.func,
    maxResults : PropTypes.number,
    nameField : PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    withController: PropTypes.bool.isRequired,
    onChange: PropTypes.func

}
AjaxAutocomplete.defaultProps = {
    baseEndpoint : "/",
    maxResults : 20,
    value: null,
    withController: true,
    placeholder: "Digita per ricercare un elemento ...",
    onChange: () => {},
    itemTemplate : (item) => {
        return (
            <div>{JSON.stringify(item)}</div>
        );
    }
}