import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

export const NotFound = () => {
	const history = useHistory();
	const style = {
		position:"relative"
	}
	return (
		<div className="exception-body notfound">
			<div className="exception-panel" style={style}>
				<div className="exception-content">
					<img src="/assets/layout/images/pages/icon-404.svg" alt="roma" />
					<h1>Pagina non trovata</h1>
					<p>La pagina che hai richiesto non è stata trovata!</p>

					<Button label="Indietro" icon="pi pi-arrow-left" onClick={() => { history.goBack() }} />
				</div>
			</div>
		</div>
	)
}