import React from 'react';

import {HasRole} from "../../utils/HasRole";

import {ModifyPage} from "../../components/ModifyPage";
import {Input} from "../../components/form/Input";
import * as yup from "yup";


export const slaCRUD = () => {

    const fields = {

        taskName : {
            label : "Nome Task",
            tableRenderer: null,
            readOnly: true,
            // schema : yup.string().required("Campo Obbligatorio"),
            formComponent : Input,
            formComponentProps : {
                
            }
        },
        scadenza : {
            label : "Scadenza",
            tableRenderer: null,
            schema : yup.string().required("Campo Obbligatorio").matches(/^[1-9]*$/,"Scadenza non valida!"),
            formComponent : Input,
            formComponentProps : {

            }
        }

    }

    return (
        <HasRole roles={["ROLE_ADMIN","ROLE_OWNER"]} mode={"ONE"} showPanel={true}>
            <ModifyPage
                entityName="ConfigurazioneSla"
                title="Gestione SLA"
                subtitle="Questa pagina consente di gestire gli SLA"
                endpoint="/api/configurazione-slas"
                fields={fields}
            />

        </HasRole>
    )
}

