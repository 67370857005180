import React from 'react';
import {Card} from 'primereact/card';
import * as PropTypes from "prop-types";
export const Page = (props) => {





    return (
            <div className="p-grid">
                <div className="p-col-12">

                        <Card title={props.title} className={"nospace"}
                              subTitle={props.subtitle}
                              header={(<div className="card-header"></div>)}
                              footer={props.footer}>
                            <hr />
                                {props.children}
                            <hr />
                        </Card>

                </div>
            </div>

    )
}
Page.propTypes = {
    children: PropTypes.node.isRequired,
    title : PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    footer :  PropTypes.element,
};
