import {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {UserContext} from './UserContext';

import {AUTH_TOKEN_KEY} from '../utils/Costants';

export default function useAuth() {
    let history = useHistory();
    const {setUser} = useContext(UserContext);
    const [error, setError] = useState(null);

    //set user
    const setUserContext = async () => {
        return await axios.get('/api/account').then(res => {

            setUser(res.data);
            history.push('/app');
        }).catch((err) => {
            setError("Impossibile recuperare le informazioni dell'utente");
        })
    }

    const updateUserContext = async () => {
        return await axios.get('/api/account').then(res => {
             setUser(res.data);
        }).catch((err) => {
            setError("Impossibile recuperare le informazioni dell'utente");
        })
    }


    //login user 
    const loginUser = async (data) => {
        setError("");
        return axios.post('api/authenticate', data).then(async (result) => {


            const bearerToken = result.headers.authorization;

            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);
                if (data.rememberMe) {
                    window.localStorage.setItem(AUTH_TOKEN_KEY, jwt);
                } else {
                    window.sessionStorage.setItem(AUTH_TOKEN_KEY, jwt);
                }
                await setUserContext();
            } else {
                setError("Username o Password_ errate");
            }


        }).catch((err) => {
            setError("Username o Password_ errate");
        })
    };
    const logoutUser = async () => {
        clearAuthToken();
        window.location.reload();

    }
    return {
        updateUserContext,
        logoutUser,
        loginUser,
        error
    }
}

export const clearAuthToken = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    // if (window.localStorage.getItem(AUTH_TOKEN_KEY)) {
    //     window.localStorage.removeItem(AUTH_TOKEN_KEY);
    // }
    // if (window.sessionStorage.getItem(AUTH_TOKEN_KEY)) {
    //     window.sessionStorage.removeItem(AUTH_TOKEN_KEY);
    // }
};
