import React from 'react';
import {Card} from 'primereact/card';
import * as PropTypes from "prop-types";
import {Form} from "./Form";

export const PageAsForm = (props) => {


    return (
            <div className="p-grid">
                <div className="p-col-12">
                    <Form onSubmit={props.onSubmit} schema={props.schema} defaultValues={props.defaultValues} values={props.values} reset={props.reset} >

                        <Card title={props.title} className={"nospace"}
                              subTitle={props.subtitle}
                              header={(<div className="card-header"></div>)}
                              footer={props.footer}>
                            <hr />

                                {props.children}

                            <hr />
                        </Card>
                    </Form>
                </div>
            </div>

    )
}
PageAsForm.propTypes = {
    children: PropTypes.node.isRequired,
    title : PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    footer :  PropTypes.element,
    onSubmit : PropTypes.func.isRequired,
    schema : PropTypes.object.isRequired,
    defaultValues: PropTypes.object,
    values:PropTypes.object,
    reset: PropTypes.bool
};
