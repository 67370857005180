import React, { useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import * as PropTypes from "prop-types";
import {TableWithSidebarFilters} from "../TableWithSidebarFilters";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {ToastContext} from "../../hooks/UtilsContext";
import { confirmDialog } from 'primereact/confirmdialog';
import {PraticaDetail} from "./PraticaDetail";
import {useHistory} from "react-router-dom";
import {DateText} from "../generic/DateText";


export const TaskListTable = (props) => {

    const [loading, setLoading] = useState(false);
    const [selectedPratica, setSelectedPratica] = useState({});
    const [showDetail, setShowDetail] = useState(false);
    const {showSuccessMessage, showWarnMessage} = useContext(ToastContext);
    const table = useRef();
    const history = useHistory();


    useEffect(() => {
    }, []);

    const prendiInCarico =  (rowdata, lavoraPratica) => {
        confirmDialog({
            message: 'Sicuro di voler prendere in carico la pratica selezionata?',
            header: 'Info',
            icon: 'pi pi-info-circle',
            acceptLabel:"Si",
            rejectLabel:"No",
            accept() {
                setLoading(true);
                axios.post("/api/task/incarico/" + rowdata.id,{})
                    .then((res)=>{
                        table.current.reload();
                        setLoading(false)
                        props.modifica(true);
                        showSuccessMessage("Info","Pratica presa in carico correttamente.");
                        if(lavoraPratica){
                            lavora(rowdata);
                        }
                        //props.ref.current.reload();
                    })
                    .catch((err)=>{
                        console.log("err >>> " + err)
                        setLoading(false);
                        showWarnMessage("Attenzione", "Impossibile prendere in carico la pratica")
                    })

            },
            reject() {
            }
        });
    }

    const prendiInCaricoAndLavora = (rowdata) => {
        prendiInCarico(rowdata,true);
    }


    const openDetail = (rowData) => {
        setSelectedPratica(rowData);
        setShowDetail(true);
    }
    const lavora =  (rowData) => {
        //table.current.reload();
        history.push("/app/pratica/lavorazione/" + rowData.id)

    }
    const actionTemplate = (rowData,table) => {
        return(
            <React.Fragment>
                <span className="p-column-title">{table.header}</span>
                <Button loading={loading} icon="pi pi-book"  title={"Dettaglio"} data-pr-tooltip={"Dettaglio"} className="p-button-rounded p-mr-1"
                        onClick={() => openDetail(rowData)}/>

                { (props.type === "ASSEGNATO") && (
                    <>
                <Button loading={loading} icon="pi pi-check" className="p-button-rounded p-button-success p-mr-1"
                        title={"Prendi in carico"} data-pr-tooltip={"Prendi in carico"}
                        onClick={() => {
                            prendiInCarico(rowData)
                        }}/>
                    <Button loading={loading} icon="pi pi-angle-double-right" className="p-button-rounded p-button-help"
                    title={"Prendi in carico & Vai alla lavorazione"} data-pr-tooltip={"Prendi in carico & Vai alla lavorazione"}
                    onClick={() => {
                    prendiInCaricoAndLavora(rowData)
                }}/>
                    </>
                )}

                { (props.type === "PRESO_INCARICO") && (
                    <Button loading={loading} icon="pi pi-angle-double-right" className="p-button-rounded p-button-success"
                            title={"Vai alla lavorazione"} data-pr-tooltip={"Vai alla lavorazione"}
                            onClick={() => {
                                lavora(rowData)
                            }}/>
                )}

            </React.Fragment>
        )
    }

    const fields = {
        id : {
            header : "Id",
            filter : {
                type: "text" 
            },
            column: true
        },
        taskName : {
            header : "Attività",
            filter : {
                type: "list", // text, list, range
                listElements: [
                    {label:"Accettazione", value:"Accettazione"},
                    {label:"Assegnazione", value:"Assegnazione"},
                    {label:"Lavorazione", value:"Lavorazione"},
                    {label:"Accettazione Lavorazione", value:"Accettazione Lavorazione"},
                    {label:"Assegnazione Squadra", value:"Assegnazione Squadra"},
                    {label:"Conferma", value:"Conferma"},
                    {label:"Conferma Finale", value:"Conferma Finale"},
                    {label:"Invio Dati", value:"Invio Dati"}
                ]
            },
            column: true
        },
        "entityData.tipoPratica.name" : {
            header : "Tipo Pratica",
            filter : {
                field:"tipoPratica.name",
                type: "ajax-list",
                pratica: "true",
                nameField: "name",
                baseEndpoint:"/api/TipoPratica"
            },
            column: true
        },
        "entityData.descrizione" : {
            header : "Descrizione",
            filter : {
                field:"descrizione",
                pratica: "true",
                type: "text" 
            },
            column: true
        },
        "entityData.contratto.codiceContratto" : {
            header : "Codice Contratto",
            filter : {
                field:"contratto.codiceContratto",
                pratica: "true",
                type: "text" 
            },
            column: false
        },
        "entityData.contratto.codiceTratta" : {
            header : "Codice Tratta",
            filter : {
                field:"contratto.codiceTratta",
                pratica: "true",
                type: "text" 
            },
            column: false
        },
        "create_time" : {
            header : "Data Creazione",
            sortable: true,
            filter : {
                type: "date" 
            },
            renderer: (rowData,table) => {
                return(
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                           <DateText value={rowData.creationDate} />
                        </div>
                    </React.Fragment>
                )
            },
            column: true
        },
        contratto : {
            header : "Contratto Attuativo",
            renderer: (rowData,table) => {
                return(
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                            <b>{rowData.entityData.contratto.descrizione}</b> <br/>
                            Tratta: <b>{rowData.entityData.contratto.codiceTratta}</b><br/> Contratto: <b>{rowData.entityData.contratto.codiceContratto}</b>
                        </div>
                    </React.Fragment>
                )
            },
            column: true
        },
        action : {
            header :"Azioni",
            headerStyle : {width:"120px"},
            renderer: actionTemplate,
            column: true

        }
        
    }
    const dialogFooter = () => {
        return (
            <div>
                <Button label="Chiudi" icon="pi pi-check" onClick={() => setShowDetail(false)} autoFocus />
            </div>
        );
    }

    return (
        
        <>


                    
            <TableWithSidebarFilters innerRef={table} endpoint={props.endpoint} pratica={false} fields={fields} />

            <Dialog  header="Dettaglio Pratica" visible={showDetail} style={{ width: '80vw' }} contentStyle={{ height: '70vw' }}
                    footer={dialogFooter}
                    onHide={() => setShowDetail(false)}>
                    <PraticaDetail data={selectedPratica.entityData}  />
                    
            </Dialog>
            </>
    );
}

TaskListTable.propTypes = {
    endpoint: PropTypes.string.isRequired,
    type : PropTypes.oneOf(['ASSEGNATO', 'PRESO_INCARICO']).isRequired,

};
TaskListTable.defaultProps = {

}