import React from 'react';
import {TabPanel, TabView} from "primereact/tabview";
import {TaskListTable} from "../../components/wf/TaskListTable";

export const TaskList = (props) => {


    return (
        <TabView>
            <TabPanel header="Pratiche Assegnate">
                <TaskListTable endpoint="/api/tasklist/filters/ASSEGNATO" type="ASSEGNATO" modifica={props.modifica} />
            </TabPanel>
            <TabPanel header="Pratiche in carico">
                <TaskListTable endpoint="/api/tasklist/filters/PRESO_INCARICO" type="PRESO_INCARICO"modifica={props.modifica}/>
            </TabPanel>
        </TabView>
    );
}