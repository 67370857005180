import React, {useState, useEffect, useContext, useRef} from 'react';
import classNames from 'classnames';


import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import {Dashboard} from './pages/Dashboard';

import { Toast } from 'primereact/toast';


import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';


import Loading from "./components/Loading";
import {UserProfile} from "./pages/user/Profile";
import PrivateRoute from "./utils/PrivateRoute";
import {UserContext} from "./hooks/UserContext";
import { hasOneRole} from "./utils/Utils";
import {Redirect, Switch} from "react-router-dom";
import { DettaglioReport } from "./pages/reports/DettaglioReport"
import { MonitoraggioSLA } from './pages/reports/MonitoraggioSLA';
import {PraticaCreate} from "./pages/pratiche/PraticaCreate";
import {TipoPraticaCrud} from "./pages/admin/TipoPraticaCrud";
import {ToastContext} from "./hooks/UtilsContext";
import {AziendaCrud} from "./pages/admin/AziendaCrud";
import {UserCrud} from "./pages/admin/UserCrud";
import {Contratti} from "./pages/admin/Contratti";
import {LavoraPratica} from "./pages/wf/LavoraPratica";
import {PraticaDetailPage} from "./pages/pratiche/PraticaDetailPage";
import {PraticaList} from "./pages/pratiche/PraticaList";
import { slaCRUD } from './pages/admin/slaCRUD';
import { SquadraCrud } from './pages/configurazione/SquadraCrud';

const App = () => {

    const [layoutMode] = useState('slim');
    const [lightMenu] = useState(true);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [isRTL] = useState(false);
    const [inlineUser] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);

    const [menuActive, setMenuActive] = useState(false);
    const [topbarColor] = useState('layout-topbar-blue');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle] = useState('filled');
    const [ripple] = useState(false);
    const {user} = useContext(UserContext);
    const toast = useRef(null);
    const menu = [
        {label: 'Home', icon: 'pi pi-fw pi-home',
            items: [{
                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/app'
            }]    
        },
        {label: 'Pratiche', icon: 'pi pi-fw pi-file',
            items: [
                { label: 'Crea Nuova', icon: 'pi pi-plus', to: '/app/pratica/new', visible: hasOneRole(user, ["ROLE_ADMIN","ROLE_OWNER"]) },
                { label: 'Elenco Pratiche', icon: 'pi pi-cog', to: '/app/pratica/list', visible: hasOneRole(user, ["ROLE_USER","ROLE_OWNER","ROLE_CONTRACTOR","ROLE_CONTRACTOR_WORKER"]) }
            ]}
        , 
        {
            label: 'Configurazione', icon: 'pi pi-fw pi-cog',  visible: hasOneRole(user, ["ROLE_CONTRACTOR"]),
            items: [
                { label: 'Squadre', icon: 'pi pi-users', to: '/app/configurazione/squadre', visible: hasOneRole(user, ["ROLE_CONTRACTOR"]) },

            ]
        },
        {   
            label: 'Admin Settings', icon: 'pi pi-fw pi-cog',  visible: hasOneRole(user, ["ROLE_ADMIN"]),
            items: [
                { label: 'Contratti', icon: 'pi pi-folder-open', to: '/app/admin/contratti', visible: hasOneRole(user, ["ROLE_ADMIN"]) },
                { label: 'Tipologie Pratica', icon: 'pi pi-tags', to: '/app/admin/tipopratica', visible: hasOneRole(user, ["ROLE_ADMIN"]) },
                { label: 'Aziende', icon: 'pi pi-briefcase', to: '/app/admin/azienda', visible: hasOneRole(user, ["ROLE_ADMIN"]) },
                { label: 'Utenti', icon: 'pi pi-users', to: '/app/admin/utenti', visible: hasOneRole(user, ["ROLE_ADMIN"]) },

            ]
        },
        {   
            label: 'SLA', icon: 'pi pi-chart-bar',  visible: hasOneRole(user, ["ROLE_ADMIN","ROLE_CONTRACTOR", "ROLE_OWNER"]),
            items: [
                { label: 'SLA CRUD', icon: 'pi pi-folder-open', to: '/app/admin/sla-crud', visible: hasOneRole(user, ["ROLE_ADMIN","ROLE_OWNER"]) },
                { label: 'Monitoraggio SLA', icon: 'pi pi-tags', to: '/app/reports/monitoraggio-sla', 
                    visible: hasOneRole(user, ["ROLE_ADMIN","ROLE_CONTRACTOR", "ROLE_OWNER"]) },
            ]
        }
    ];

    const routers = [
        {path: '/app', component: Dashboard, exact: true},
        {path: '/app/pratica/new', component: PraticaCreate, exact: true},
        {path: '/app/pratica/lavorazione/:taskID', component: LavoraPratica, exact: true},
        {path: '/app/pratica/dettaglio/:praticaID', component: PraticaDetailPage, exact: true},
        {path: '/app/pratica/list', component: PraticaList, exact: true},
        {path: '/app/configurazione/squadre', component: SquadraCrud, exact: true},
        {path: '/app/admin/contratti', component: Contratti, exact: true},
        {path: '/app/admin/tipopratica', component: TipoPraticaCrud, exact: true},
        {path: '/app/admin/azienda', component: AziendaCrud, exact: true},
        {path: '/app/admin/utenti', component: UserCrud, exact: true},
        {path: '/app/admin/sla-crud', component: slaCRUD, exact: true},
        {path: '/app/loading', component: Loading, exact: true},
        {path: '/app/reports/dettaglio/:id', component: DettaglioReport, exact: true},
        {path: '/app/reports/monitoraggio-sla', component: MonitoraggioSLA, exact: true},

    ];

    let topbarItemClick;
    let menuClick;

    let configClick = false;

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);


    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }


        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;


        configClick = false;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (layoutMode === 'overlay') {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }

        if (isDesktop())
            setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
        else {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }

        event.preventDefault();
    };

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevTopbarMenuActive => !prevTopbarMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null);
        else
            setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    };

    const onMenuClick = () => {
        menuClick = true;
    };


    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };


    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);

    };

    const isDesktop = () => {
        return window.innerWidth > 896;
    };


    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    };

    const isSlim = () => {
        return layoutMode === 'slim';
    };


    const layoutClassName = classNames('layout-wrapper', {
        'layout-horizontal': layoutMode === 'horizontal',
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-slim': layoutMode === 'slim',
        'layout-menu-light': lightMenu,
        'layout-menu-dark': !lightMenu,
        'layout-overlay-active': overlayMenuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-static-inactive': staticMenuDesktopInactive,
        'layout-rtl': isRTL,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple
    }, topbarColor);

    const showSuccessMessage = (title,content) =>{
        toast.current.show({severity:'success', summary: title, detail:content, life: 3000});
    }
    const showWarnMessage = (title,content) =>{
        toast.current.show({severity:'warn', summary: title, detail:content, life: 3000});
    }
    const showErrorMessage = (title,content) =>{
        toast.current.show({severity:'error', summary: title, detail:content, life: 3000});
    }
    const showInfoMessage = (title,content) =>{
        toast.current.show({severity:'info', summary: title, detail:content, life: 3000});
    }


    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Toast ref={toast} />
            <AppTopbar topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem} inlineUser={inlineUser}
                       onMenuButtonClick={onMenuButtonClick}
                       onTopbarMenuButtonClick={onTopbarMenuButtonClick} onTopbarItemClick={onTopbarItemClick}/>

            {/*<AppRightMenu rightPanelMenuActive={rightPanelMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>*/}

            <div className="layout-menu-container" onClick={onMenuClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick}
                         layoutMode={layoutMode} active={menuActive} mobileMenuActive={staticMenuMobileActive}/>
            </div>

            <ToastContext.Provider value={{showInfoMessage, showErrorMessage, showWarnMessage, showSuccessMessage}}>
                <div className="layout-main">
                    <div className="layout-content">
                        <Switch>
                        {
                            routers.map((router, index) => {
                                if (router.exact) {
                                    return <PrivateRoute key={`router${index}`} path={router.path} exact
                                                         component={router.component}/>
                                }

                                return <PrivateRoute key={`router${index}`} path={router.path}
                                                     component={router.component}/>
                            })
                        }
                        <PrivateRoute exact path="/app/user/profile" component={UserProfile}/>
                        <Redirect to="/notfound" />
                        </Switch>
                    </div>

                    <AppFooter/>
                </div>
            </ToastContext.Provider>

            <div className="layout-content-mask"></div>
        </div>
    );

}

export default App;
