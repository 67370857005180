import React from 'react';
import { Button } from 'primereact/button';
import {useHistory} from "react-router-dom";


export const Landing = () => {
    const history = useHistory();
    const style = {

        background: "url(/assets/backtopInt.gif) repeat-x ",

        backgroundColor: "#FFFFFF",
        width: "100%",
        height: "vh",

    }



    return (
        <div style={style}>


                <div className="p-mb-3 p-text-center">

                    <img src="/assets/geo4wipPlus.png" style={{width:"30vw", marginTop:"30px"}}/>
                    <h1>Benvenuto su Geo4Man</h1>
                    <img alt="login" src="/assets/undraw_professor_8lrt.svg" style={{width:"30vw", marginTop:"30px"}}/><br/>
                    <Button label={"Accedi al Sistema."} className="p-button-success" onClick={() => { history.push("/app") }}></Button>
                    <div style={{height:"50px"}}> </div>
                </div>


        </div>
    );
}

