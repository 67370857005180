import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';

import AppWrapper from './AppWrapper';
import axios from 'axios';
import {onRequestSuccess,onResponseSuccess,onResponseError} from './utils/axios-interceptor'
import { it } from 'yup-locales';
import { setLocale } from 'yup';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {BrowserRouter} from "react-router-dom";


library.add(fas);



axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.request.use(onRequestSuccess);
axios.interceptors.response.use(onResponseSuccess, onResponseError);

setLocale(it);

ReactDOM.render(

		<BrowserRouter>
			<AppWrapper></AppWrapper>
		</BrowserRouter>
	,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
