import React, { useState} from 'react';
import {Page} from "../../components/Page";
import {PraticaDetail} from "../../components/wf/PraticaDetail";
import Loading from "../../components/Loading";
import {TableWithSidebarFilters} from "../../components/TableWithSidebarFilters";
import {DateText} from "../../components/generic/DateText";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

export const PraticaList = (props) => {
    const [loading, setLoading] = useState(false);
    const [selectedPratica, setSelectedPratica] = useState({});
    const [showDetail, setShowDetail] = useState(false);


    const openDetail = (rowData) => {
        setSelectedPratica(rowData);
        setShowDetail(true);
    }

    const actionTemplate = (rowData,table) => {
        return(
            <React.Fragment>
                <span className="p-column-title">{table.header}</span>
                <Button loading={loading} icon="pi pi-book"  title={"Dettaglio"} data-pr-tooltip={"Dettaglio"} className="p-button-rounded"
                        onClick={() => openDetail(rowData)}/>
            </React.Fragment>
        )
    }

    const fields = {
        id : {
            header : "Id",            
            filter : {
                type: "id" 
            },
            column: true
        },
        descrizione : {
            header : "Descrizione",
            filter : {
                type: "text" // text, list, range
            },
            column: true
        },
        "createdDate" : {
            header : "Data Creazione",
            sortable: true,
            filter : {
                type: "date" 
            },
            renderer: (rowData,table) => {
                 return(
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                            <DateText value={rowData.createdDate} />
                        </div>
                    </React.Fragment>
                )
            },
            column: true
        },
        currentTaskName: {
            header : "Attività",
            sortable: true,
            filter : {
                type: "list", // text, list, range
                listElements: [
                    {label:"Accettazione", value:"Accettazione"},
                    {label:"Assegnazione", value:"Assegnazione"},
                    {label:"Lavorazione", value:"Lavorazione"},
                    {label:"Accettazione Lavorazione", value:"Accettazione Lavorazione"},
                    {label:"Assegnazione Squadra", value:"Assegnazione Squadra"},
                    {label:"Conferma", value:"Conferma"},
                    {label:"Conferma Finale", value:"Conferma Finale"},
                ]
            },
            column: true
        },
        status: {
            header : "Stato",
            sortable: true,
            filter : {
                type: "list", // text, list, range
                listElements: [

                    {label:"ASSEGNATO", value:"ASSEGNATO"},
                    {label:"PRESO_INCARICO", value:"PRESO_INCARICO"},
                    {label:"IN_SOSTA", value:"IN_SOSTA"},
                    {label:"IN_LAVORAZIONE", value:"IN_LAVORAZIONE"},
                    {label:"FINE_LAVORAZIONE", value:"FINE_LAVORAZIONE"},
                    {label:"CHIUSO", value:"CHIUSO"},
                    {label:"COMPLETATO", value:"COMPLETATO"},


                ]
            },
            column: true
        },
        "tipoPratica.name" : {
            header : "Tipo Pratica",
            sortable: true,
            filter : {
                field:"tipoPratica.id",
                type: "ajax-list",
                nameField: "name",
                baseEndpoint:"/api/TipoPratica"
            },
            column: true

        },

        contratto : {
            header : "Contratto Attuativo",
            renderer: (rowData,table) => {
                return(
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                            <b>{rowData.contratto.descrizione}</b> <br/>
                            Tratta: <b>{rowData.contratto.codiceTratta}</b><br/> Contratto: <b>{rowData.contratto.codiceContratto}</b>
                        </div>
                    </React.Fragment>
                )
            },
            column: true
        },
        "contratto.codiceContratto" : {
            header : "Codice Contratto",
            filter : {
                type: "text" 
            },
            column: false
        },
        "contratto.codiceTratta" : {
            header : "Codice Tratta",
            filter : {
                type: "text" 
            },
            column: false
        },
        action : {
            header : "Azioni",
            headerStyle: {width:"75px"},
            renderer: actionTemplate,
            column: true
        }




    }

    const dialogFooter = () => {
        return (
            <div>
                <Button label="Chiudi" icon="pi pi-check" onClick={() => setShowDetail(false)} autoFocus />
            </div>
        );
    }
    return (
        <>
            {loading && (<Loading/>)}
            {!loading && (
            <Page title={"Elenco Pratiche"} subtitle={"In questa pagina è possibile visualizzare e ricercare le pratiche di manutenzione."}>

                <TableWithSidebarFilters endpoint={"/api/pratica"} fields={fields} pratica={true} />
                <Dialog  header="Dettaglio Pratica" visible={showDetail} style={{ width: '80vw' }} contentStyle={{ height: '70vw' }}
                         footer={dialogFooter}
                         onHide={() => setShowDetail(false)}>
                    <PraticaDetail data={selectedPratica}  />
                </Dialog>
            </Page>
            )}
        </>

    )
}





