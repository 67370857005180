import React from 'react';
import {SITE_NAME} from './utils/Costants'
const AppFooter = () => {
    const style = {
        padding: "0.1em 0.5em 0.1em 0.5em"
    }
    return (
        <div className="layout-footer" style={style}>
            <div>

                <p>{SITE_NAME}</p>
            </div>
            <div className="footer-icons">
                <button type="button" className="p-link">
                    <i className="pi pi-home"></i>
                </button>
                <button type="button" className="p-link">
                    <i className="pi pi-globe"></i>
                </button>
                <button type="button" className="p-link">
                    <i className="pi pi-envelope"></i>
                </button>
            </div>
        </div>
    )
}

export default AppFooter;