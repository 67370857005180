import React, {useEffect, useRef, useState} from 'react';
import {Page} from "../../components/Page";
import {PraticaDetail} from "../../components/wf/PraticaDetail";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import Loading from "../../components/Loading";
import {Messages} from "primereact/messages";
import {Button} from "primereact/button";

export const PraticaDetailPage = (props) => {
    const [loading, setLoading] = useState(true);
    const [pratica, setPratica] = useState(undefined);
    const { praticaID } = useParams();
    const msgs = useRef(null);
    const history = useHistory();
    
    useEffect(() => {

        setLoading(true);
        axios.get("/api/pratica/" + praticaID)
            .then((res)=>{
                setLoading(false);
                setPratica(res.data);
            })
            .catch((err)=>{
                setLoading(false);
                msgs.current?.replace({ severity: 'warn', summary: '', detail: "Impossibile caricare il dettaglio della pratica", sticky: true });
            })

    },[praticaID])

    


    const footer = (<>
        <Button label="Vai ad Elenco Pratiche" icon="pi pi-list" className="p-button-info" onClick={()=>{history.push("/app/pratica/list")}}/>
        <Button label="Vai alla DashBoard" icon="pi pi-home" className="p-button-success" onClick={()=>{history.push("/app")}} style={{marginLeft: '.25em'}}/>
    </>);
    return (
        <>
            {loading && (<Loading/>)}
            {!loading && (
            <Page title={"Dettaglio Pratica"} footer={footer}>
                <Messages ref={msgs} />
                {pratica && (<PraticaDetail data={pratica}  />)}

            </Page>
            )}
        </>

    )
};

PraticaDetailPage.propTypes = {
   // data: PropTypes.object.isRequired,
};
PraticaDetailPage.defaultProps = {
   // data: {}
}




