import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import axios from "axios";
import * as PropTypes from "prop-types";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Input } from './form/Input';
import { AjaxDropdown } from './form/AjaxDropdown';
import { Sidebar } from 'primereact/sidebar';
import { Calendar } from 'primereact/calendar';

//https://dzone.com/articles/advanced-search-amp-filtering-api-using-spring-dat

export const TableWithSidebarFilters = (props) => {

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filterValue, setFilterValue] = useState({});
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [filters, setFilters] = useState([]);


    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: 1,
        filters: {}

    });

    const dt = useRef();


    useImperativeHandle(props.innerRef, () => ({
        reload: () => {
            loadLazyData();
        }
    }));

    const changeFilter = (fieldName, type, field, value, pratica) => {
        
        if (((value !== undefined || value !== null) && value.length > 0) ||  (value !== null && type !== undefined &&
        (type === 'list' || type === 'ajax-list' || type ==='date')))  {
            let filter = {};
            filter[fieldName] = { value: type === 'date' ? new Date(value.value) : value, type: type, field: field, pratica:pratica };
            let fff = { ...filterValue, ...filter };
            setFilterValue(fff);
        } else {
            let fff = { ...filterValue }
            delete fff[fieldName];
            setFilterValue(fff);

        }


    }

    const applyFilter = (reset) => {
        let _fValue = filterValue;
        if (reset) {
            _fValue = {}
            setFilterValue({})
        }
        let f = { filters: _fValue };
        let _lazyParams = { ...lazyParams, ...f };
        _lazyParams['first'] = 0;
        _lazyParams['page'] = 0;

        setLazyParams(_lazyParams);
        setSidebarVisible(false);


    }



    useEffect(() => {
        let filters_tmp = [];
        let columns = Object.keys(props.fields).map((fieldName, i) => {
            let isSortable = props.fields[fieldName].sortable ? true : false;
            let bodyt = bodyTemplate;
            if (props.fields[fieldName].renderer !== undefined) {
                bodyt = props.fields[fieldName].renderer;
            }
            if (props.fields[fieldName].filter !== undefined) {
                let filterObj = props.fields[fieldName].filter;
                filterObj.placeholder = filterObj.placeholder ? filterObj.placeholder : props.fields[fieldName].header;
                filterObj.field = filterObj.field ? filterObj.field : fieldName;
                filterObj.pratica = filterObj.pratica ? filterObj.pratica : false;
                if (filterObj.type === "text" || filterObj.type === "id") {
                    filterObj.component = <Input key={fieldName}
                        withController={false}
                        name={fieldName}
                        value={filterValue[fieldName]?.value}
                        label={filterObj.placeholder}
                        onChange={(val) => { changeFilter(fieldName, filterObj.type, filterObj.field, val, filterObj.pratica) }} />
                }
                else if (filterObj.type === "list") {

                    filterObj.component = (
                        <div className="p-field" key={fieldName}>
                            <label htmlFor={fieldName} className="p-d-block">{filterObj.placeholder}</label>
                            <Dropdown key={fieldName} id={fieldName} value={filterValue[fieldName]?.value}
                                options={filterObj.listElements}
                                onChange={(e) => { changeFilter(fieldName, filterObj.type, filterObj.field, e.value, filterObj.pratica) }}
                                optionLabel="label" showClear={true} placeholder="Seleziona..." />

                        </div>
                    )
                }
                else if (filterObj.type === "ajax-list") {
                    filterObj.component = (<AjaxDropdown
                        cache={false}
                        withController={false}
                        key={fieldName}
                        name={fieldName}
                        value={filterValue[fieldName]?.value}
                        label={filterObj.placeholder}
                        nameField={filterObj.nameField}
                        baseEndpoint={filterObj.baseEndpoint}
                        onChange={(val) => { changeFilter(fieldName, filterObj.type, filterObj.field, val, filterObj.pratica) }}
                    />)
                } else if (filterObj.type === "date") {
                    filterObj.component = (
                        <div className="p-field" key={fieldName}>
                            <label htmlFor={fieldName} className="p-d-block">{filterObj.placeholder}</label>
                            <Calendar
                                name={fieldName}
                                showIcon
                                dateFormat="dd/mm/yy"
                                showButtonBar
                                value={filterValue[fieldName]?.value}
                                onChange={(val) => { changeFilter(fieldName, filterObj.type, filterObj.field, val, filterObj.pratica) }}></Calendar>
                        </div>)
                }
                filters_tmp.push(filterObj);

            }
            let sortable = {};
            if (!isSortable) {
                sortable.sortableDisabled = true;
            } else {
                sortable.sortable = true;
            }
            if (props.fields[fieldName].column)
                return <Column key={`${fieldName}-${i}`} headerStyle={props.fields[fieldName].headerStyle} {...sortable} body={bodyt} field={fieldName} header={props.fields[fieldName].header} />
        });
        setFilters(filters_tmp);
        setColumns(columns);


    }, [filterValue]);


    useEffect(() => {
        loadLazyData();
        /*return () => {
            setData([]);
            setTotalRecords(0);
            };*/
    }, [lazyParams])

    const loadLazyData = () => {
        setLoading(true);
        let sortOrderParam = lazyParams.sortOrder === 1 ? "ASC" : "DESC";
        let sortParam = lazyParams.sortField ? lazyParams.sortField + "," + sortOrderParam : null;
        
        if(props.pratica){
            let filters = [];
            
            filters = Object.keys(lazyParams.filters).map((fieldName)=>{
                if(lazyParams.filters[fieldName].value !== ""){
                    if(lazyParams.filters[fieldName].type === "text"){
                        return `${lazyParams.filters[fieldName].field}|like|${lazyParams.filters[fieldName].value}`;
                    }else if(lazyParams.filters[fieldName].type === "list" || lazyParams.filters[fieldName].type === "id"){
                        return `${lazyParams.filters[fieldName].field}|eq|${lazyParams.filters[fieldName].value}`;
                    }else if(lazyParams.filters[fieldName].type === "ajax-list"){
                        return `${lazyParams.filters[fieldName].field}|eq|${lazyParams.filters[fieldName].value.id}`;
                    }else if(lazyParams.filters[fieldName].type === "date"){
                        return `${lazyParams.filters[fieldName].field}|gte|${new Date(lazyParams.filters[fieldName].value).toISOString()}|lte|${new Date(lazyParams.filters[fieldName].value).toISOString()}`;
                    }
                }
            });
            axios.get(props.endpoint,{
                params: {
                    page: lazyParams.page,
                    size: lazyParams.rows,
                    sort: sortParam,
                    filterAnd: filters.join("&")
                }
            }).then(res => {
                setLoading(false);
                setData(res.data);
                setTotalRecords(parseInt(res.headers["x-total-count"]));                
                return () => {
                    setData([]);
                    setTotalRecords(0);
                };
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
        }else{
            let filters = [];
            
            Object.keys(lazyParams.filters).map((fieldName) => {
                    if (lazyParams.filters[fieldName].type === "ajax-list") {
                        filters.push({ "name": lazyParams.filters[fieldName].field, "value": lazyParams.filters[fieldName].value.name, "type":"LIST", "pratica":lazyParams.filters[fieldName].pratica});
                    } else {
                        {((lazyParams.filters[fieldName].value !== null && lazyParams.filters[fieldName].value.length > 0) 
                            || lazyParams.filters[fieldName].field === 'create_time')
                        && filters.push({ "name": lazyParams.filters[fieldName].field, "value": lazyParams.filters[fieldName].value,  "type":"STRING", "pratica":lazyParams.filters[fieldName].pratica});}
                    }
            });
            axios.post(props.endpoint + `?size=${lazyParams.rows}&page=${lazyParams.page}&first=${lazyParams.first} ${sortParam != null ? `&sort=${sortParam}` : ''}`, 
                        filters)
            .then(res => {
                setLoading(false);
                setData(res.data);
                setTotalRecords(parseInt(res.headers["x-total-count"]));
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });

        }
    

        
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }
    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }
    
    const bodyTemplate = (rowData, table) => {
        return (
            <React.Fragment>
                <span id={`${table.header}-${rowData.length*2}`} className="p-column-title">{table.header}</span>
                <div id={`${rowData.id}`} className={table.header === "Id" || table.header === "Descrizione" ? "p-text-nowrap p-text-truncate" : ""} 
                title={table.header === "Id" || table.header === "Descrizione" ? table.field.split('.').reduce((o, i) => o[i], rowData):""}>
                    {table.field.split('.').reduce((o, i) => o[i], rowData)}
                </div>
            </React.Fragment>
        );
    }
    

    const openFilterSidebar = () => {
        setSidebarVisible(true);
    }


    const header = (
        <div className="table-header">
            <b>Record Totali:</b> {totalRecords} {Object.keys(lazyParams.filters).length > 0 && (<i>(Filtrati)</i>)}
            <Button type="button" label="Ricerca (Ctrl+f)" className="p-button-outlined" icon="pi pi-filter" onClick={openFilterSidebar} style={{ float: "right" }} />
            <div style={{ clear: "both" }}></div>
        </div>
    );
    window.addEventListener("keydown", function (e) {
        if (e.ctrlKey && e.code === "KeyF") {
            e.preventDefault();
            openFilterSidebar();
        }
    });



    return (
        <div className="card">
            <div className="datatable-responsive-demo">
                <DataTable ref={dt} value={data} lazy loading={loading}
                    emptyMessage={props.emptyMessage} header={header}
                    className="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
                    paginator first={lazyParams.first} rows={lazyParams.rows} totalRecords={totalRecords} onPage={onPage}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Record da {first} a {last} di {totalRecords} totali." rowsPerPageOptions={[10, 20, 50]}
                    onSort={onSort} sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder} removableSort
                >
                    {columns}
                </DataTable>
            </div>
            <Sidebar visible={sidebarVisible} position="right" style={{ width: '30vw'}} onHide={() => setSidebarVisible(false)}>
                <h1 style={{ fontWeight: 'normal' }}>Ricerca</h1>
                <div className="p-grid">
                {filters.map((f) => { return <>
                {<div className="p-lg-6 p-md-6 p-xs-12">{f.component}</div>}
                </> })}</div>
                {filters.length > 0 && (
                    <>

                        <Button type="button" label="Reset" className="p-button-warn" icon="pi pi-times" onClick={() => { applyFilter(true) }} />
                        <Button type="button" label="Applica" className="p-button-success" style={{ float: "right" }} icon="pi pi-check" onClick={() => { applyFilter(false) }} />


                    </>)}



                {filters.length === 0 && (<p>Nessun filtro disponibile.</p>)}

            </Sidebar>
        </div>
    );


}

TableWithSidebarFilters.propTypes = {
    endpoint: PropTypes.string.isRequired,
    fields: PropTypes.objectOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        headerStyle: PropTypes.object,
        renderer: PropTypes.isRequired,
        sortable: PropTypes.bool,
        filterable: PropTypes.bool,
    })).isRequired,
    onLoad: PropTypes.func,
    getSla: PropTypes.func,
    emptyMessage: PropTypes.string,
    innerRef: PropTypes.object,

};
TableWithSidebarFilters.defaultProps = {
    onLoad: (data) => { return data },
    emptyMessage: "Nessun elemento presente.",
    innerRef: null
} 