import {Access} from "../pages/Access";
import { useContext} from "react";
import {UserContext} from "../hooks/UserContext";
import {hasAllRole, hasOneRole} from "./Utils";
import PropTypes from 'prop-types';

//roles
export const HasRole = (props) => {

    const {user} = useContext(UserContext);

    let auth = props.mode === 'ALL' ? hasAllRole(user, props.roles) : hasOneRole(user, props.roles);
    if (auth) {
        return (
            <>
                {props.children}
            </>
        )
    } else {
        return props.showPanel ? (<Access/>) : ( <></>);
    }


}

HasRole.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    mode: PropTypes.oneOf(['ALL', 'ONE']),
    showPanel: PropTypes.bool
}
HasRole.defaultProps = {
    roles: [],
    mode: "ALL",
    showPanel: true
}