import React, {useContext, useEffect, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {FormContext, FormItemContext} from "../../hooks/FormContext";
import {CondionalControllerWrapper} from "../../utils/CondionalControllerWrapper";
import PropTypes from "prop-types";




export const Input = (props) => {
    const {control} = useContext(FormContext) || {control: {}};
    return (
        <CondionalControllerWrapper name={props.name} wrap={props.withController} control={control}>
            <FormItemContext.Consumer>
                {field => <InnerInput {...props} />}
            </FormItemContext.Consumer>
        </CondionalControllerWrapper>
    )
};
export function InnerInput(props) {
    const initValue = "";
    const {errors} = useContext(FormContext)  || {register: ()=>{},control: {},errors: {}};
    const field = useContext(FormItemContext) || {field:{}}
    const [value, setValue] = useState(initValue);

    const applyValue = (newVal) => {
        if(newVal !== null || newVal !== undefined){
            setValue(newVal)
            return newVal;
        }else{
            setValue(initValue);
            return initValue;
        }
    }

    useEffect(() => {

        if(props.withController){
            applyValue(field.value);
        }else{
            applyValue(props.value);
        }


    },[props.value, field.value]);

    const onChange=(e)=>{
        let newVal = applyValue(e.target.value);
        if (props.withController) {
            field.onChange(newVal);
        }
        props.onChange(newVal);
    }


    return(
        <div className="p-field">
            <label htmlFor={props.name} className={classNames("p-d-block",{ 'p-error': errors[props.name] })}>{props.label}</label>
            <InputText disabled={props.readonly} id={props.name}  value={value} onChange={onChange} placeholder={props.placeholder}  className={classNames({ 'p-invalid': errors[props.name] })} />
            <small style={{display:"block"}} className="p-error">{errors[props.name]?.message}</small>
        </div>
    )
}
Input.propTypes = {
    onSelect : PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    withController: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    readonly: PropTypes.bool,
    value:PropTypes.string

}
Input.defaultProps = {
    readonly : false,
    onChange : () =>{}
}