import React, {useContext, useEffect, useState} from 'react';
import {Dropdown} from 'primereact/dropdown';
import axios from "axios";
import PropTypes from "prop-types";
import {classNames} from "primereact/utils";
import {FormContext, FormItemContext} from "../../hooks/FormContext";
import {CondionalControllerWrapper} from "../../utils/CondionalControllerWrapper";
import {getSessionStorageOrDefault, setSessionStorage} from "../../utils/Utils";


export const AjaxDropdown = (props) => {
    const {control} = useContext(FormContext) || {control: {}};
    return (
        <CondionalControllerWrapper name={props.name} wrap={props.withController} control={control}>
            <FormItemContext.Consumer>
                {field => <AjaxDropdownInner {...props} />}
            </FormItemContext.Consumer>
        </CondionalControllerWrapper>
    )
};

export const AjaxDropdownInner = (props) => {
    const initValue = null;
    const {errors} = useContext(FormContext) || {
        register: () => {
        }, control: {}, errors: {}
    };
    const field = useContext(FormItemContext) || {field: {}}
    const [value, setValue] = useState(initValue);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    let storageKey = "AjaxDropdown/"+props.name;
    
    const applyValue = (newVal) => {
        if(newVal !== null || newVal !== undefined){
            setValue(newVal)
            return newVal;
        }else{
            setValue(initValue);
            return initValue;
        }
    }


    const load = () => {
        setLoading(true);
        if(props.cache){
            let stored = getSessionStorageOrDefault(storageKey, false);
            if(stored) {
                setLoading(false);
                setItems(stored);
            }else{
                ajax().then((res) => {
                    setLoading(false);
                    setItems(res.data);
                    setSessionStorage(storageKey,res.data)
                }).catch((err) => {
                    setLoading(false);
                    setItems([]);
                })
            }
        }else{
            ajax().then((res) => {
                setLoading(false);
                setItems(res.data);
            }).catch((err) => {
                setLoading(false);
                setItems([]);
            })
        }

    }
    const ajax = () => {
        return axios.get(props.baseEndpoint, {
            params: {
                page: 0,
                size: props.maxResults
            }
        });
    }

    useEffect(() => {
        load();
        if(props.value){
            setValue(props.value);
        }
    }, []);
    useEffect(() => {

        if(props.withController){
            applyValue(field.value);
            if(props.value){
                field.onChange(props.value);
                setValue(props.value)
            }
        }else{
            applyValue(props.value);
        }


    },[props.value, field.value]);


    const change = (e) => {
        let newVal = applyValue(e.value);

        if (props.withController) {
            field.onChange(newVal);
        }
        props.onChange(newVal);
    }

    const itemTemplate= (item) => {
        if(props.itemTemplate) return props.itemTemplate(item,props);
        return (
            <div>{item[props.nameField]}</div>
        );
    }
    const valueTemplate= (item) => {
        if(props.valueTemplate) return props.valueTemplate(item,props);
        if (item) {
            return (
                <span>{item ? item[props.nameField] : ""}</span>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    return (
        <div className="p-field">
            <label htmlFor={props.name}
                   className={classNames("p-d-block", {'p-error': errors[props.name]})}>{props.label}</label>

            <Dropdown
                disabled={loading}
                value={value}
                options={items} onChange={change}
                optionLabel={props.nameField} filter showClear
                filterBy={props.nameField}
                placeholder={props.placeholder}
                valueTemplate={valueTemplate}
                className={classNames({'p-invalid': errors[props.name]})}
                itemTemplate={itemTemplate}
                onChange={change}/>

            <small className="p-error" style={{display: "block"}}>{errors[props.name]?.message}</small>
        </div>

    )



};
AjaxDropdown.propTypes = {
    baseEndpoint: PropTypes.string.isRequired,
    itemTemplate: PropTypes.func,
    valueTemplate: PropTypes.func,
    maxResults: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.object,
    nameField: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    withController: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    label : PropTypes.string,
    cache : PropTypes.bool


}
AjaxDropdown.defaultProps = {
    baseEndpoint: "/",
    maxResults: 20,
    value: null,
    withController: true,
    placeholder: "Seleziona ...",
    cache: false,
    onChange: () => {
    },


}