import React, {useContext, useEffect, useRef, useState} from 'react';
import {Card} from 'primereact/card';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import * as PropTypes from "prop-types";
import axios from "axios";
import {Form} from "./Form";
import {ToastContext} from "../hooks/UtilsContext";
import * as yup from "yup";

export const ModifyPage = (props) => {
    const [formReset, setFormReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [item, setItem] = useState({});

    const [columns, setColumns] =   useState([]);
    const [itemDialog, setItemDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState("NEW"); // NEW, EDIT, DETAIL
    
    const {showSuccessMessage, showWarnMessage} = useContext(ToastContext);
    const dt = useRef(null);


    const [schema, setSchema] =   useState(yup.object().shape({}));
    const [formFields, setFormFields] =   useState([]);



    useEffect(() => {

        let columns = Object.keys(props.fields).map((fieldName)=>{
            if(props.fields[fieldName].tableRenderer != null){
                return <Column key={fieldName} body={props.fields[fieldName].tableRenderer } field={fieldName} header={props.fields[fieldName].label}/>;
            }else {
                return <Column key={fieldName} body={bodyTemplate} field={fieldName} header={props.fields[fieldName].label}/>;
            }
        });
        setColumns(columns);

        let ff = Object.keys(props.fields).map((fieldName)=>{
            let readOnly = props.fields[fieldName].readOnly || false;
            let Component = props.fields[fieldName].formComponent;
            return <Component readonly={readOnly} withController={true}  key={fieldName} name={fieldName} label={props.fields[fieldName].label} {...props.fields[fieldName].formComponentProps} />
        });
        setFormFields(ff)


        let innerSchema = {};
        Object.keys(props.fields).forEach((fieldName)=>{
            innerSchema[fieldName] = props.fields[fieldName].schema;
        });
        setSchema(yup.object().shape({...innerSchema}));

        loadData();
    }, []);

    const bodyTemplate = (rowData,table) =>{

        return (
            <React.Fragment>
                <span className="p-column-title">{table.header}</span>
                {rowData[table.field]}
            </React.Fragment>
        );
    }

    const editItem = (local_item) => {
        setDialogMode("EDIT");
        setItemDialog(true);
        setItem(local_item);
    }
    const reset = () => {
        setFormReset(true);
        setFormReset(false);
        setItem({});

    }
    
    const hideDialog = () => {
        reset();
        setItemDialog(false);
    }
    
    const loadData = () => {

        setLoading(true);
        axios.get(props.endpoint).then((r) => {
            setLoading(false);
            setData(props.onLoad(r.data));

        })
            .catch(e => {
                setLoading(false);
             //   showWarnMessage("Attenzione",e.response.data.detail);
            });
    }
    const saveItem =  async (data) => {
        data = props.onSubmit(data);
        let ajax = axios.post;
        let endpoint = props.endpoint ;
        if(data.hasOwnProperty("id") && dialogMode === "EDIT"){
            ajax = axios.put;
            endpoint = props.endpoint + "/" + data.id
        }
        setLoading(true);
        ajax(endpoint, data)
            .then((r) => {
                setLoading(false);
                loadData();
                showSuccessMessage("Info","Operazione eseguita con successo!")
                hideDialog();
            })
            .catch(e => {
                setLoading(false);
                showWarnMessage("Attenzione",e.response.data.detail || e.response.data.title);
            });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" title="Modifica"
                        onClick={() => editItem(rowData)}/>
            </React.Fragment>
        );
    }
    // const leftToolbarTemplate = () => {
        
    // }

    // const rightToolbarTemplate = () => {
    //     return (
    //         <React.Fragment>
    //             <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
    //             <Button label="Export CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>
    //         </React.Fragment>
    //     )
    // }

    const dialogFooter = () => {
        return (
            <div>
                <Button type="reset" loading={loading} label="Annulla" icon="pi pi-times" onClick={()=>hideDialog()}  className="p-button-warning"  style={{float:"left"}}/>
                <Button type="submit" loading={loading} label="Salva" icon="pi pi-check"  autoFocus />
            </div>
        )
    }

    const dialogContent = () => {
        if(itemDialog) {

            if (dialogMode === "NEW" || dialogMode === "EDIT") {

                return (
                    <React.Fragment>
                        {formFields}
                    </React.Fragment>
                )
            } else if (dialogMode === "DETAIL") {
                return (
                    <React.Fragment>
                    </React.Fragment>
                )
            }
        }
        return (<React.Fragment></React.Fragment>)


    }


    return (
        <>
            <div className="p-grid">
                <div className="p-col-12">

                    <Card title={props.title} className={"nospace"}
                          subTitle={props.subtitle}
                          header={(<div className="card-header"></div>)}
                          footer={props.footer}>
                        <hr/>
                        
                        <div className="datatable-responsive-demo">
                            <DataTable ref={dt} value={data} lazy loading={loading} className="p-datatable-responsive-demo"
                                       emptyMessage="Nessun elemento presente.">
                                {columns}
                                <Column headerStyle={{width:"100px"}} body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                        <hr/>
                    </Card>

                </div>
            </div>
            <Form onSubmit={saveItem} schema={schema} reset={formReset} values={item} >

                <Dialog visible={itemDialog} style={{ width: '50vw' }} header={"Aggiungi/Modifica " + props.entityName} modal className="p-fluid"  appendTo="self"
                        onHide={hideDialog} footer={dialogFooter}>
                    {dialogContent()}
                </Dialog>
            </Form>

        </>

    )
}
ModifyPage.propTypes = {
    entityName:  PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
    fields: PropTypes.objectOf(PropTypes.shape({
        label : PropTypes.string.isRequired,
        tableRenderer: PropTypes.isRequired,
        schema : PropTypes.isRequired,
        formComponent : PropTypes.isRequired,
        formComponentProps : PropTypes.object.isRequired

    })).isRequired,
    onLoad: PropTypes.func,
    onSubmit: PropTypes.func,



};
ModifyPage.defaultProps = {
    onLoad: (data)=>{return data},
    onSubmit: (data)=>{return data},
}