import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom';
import { UserContext } from './hooks/UserContext';
import PrivateRoute from './utils/PrivateRoute';
import {Login} from './pages/Login';
import {NotFound} from './pages/NotFound';
import App  from './App';
import useFindUser from './hooks/useFindUser';
import {Landing} from "./pages/Landing";
import {PasswordRest} from "./pages/PasswordReset";


function AppWrapper() {

	const {
		user,
		setUser,
		isLoading } = useFindUser();

	return (


	<Router>
			<UserContext.Provider value={{ user, setUser, isLoading }}>
				<Switch>
					<Route exact path="/login" component={Login}/>
					<Route exact path="/" component={Landing}/>
					<PrivateRoute path="/app" component={App}/>
					<Route exact path="/account/reset/finish" component={PasswordRest}/>
					<Route component={NotFound}/>
				</Switch>
			</UserContext.Provider>
	</Router>


	);
}

export default AppWrapper;


/*
import React, { useEffect } from 'react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';

const AppWrapper = (props) => {
	let location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

	switch (props.location.pathname) {
		case '/login':
			return <Route path="/login" component={Login} />
		case '/error':
			return <Route path="/error" component={Error} />
		case '/notfound':
			return <Route path="/notfound" component={NotFound} />
		case '/access':
			return <Route path="/access" component={Access} />
		default:
			return <App />;
	}

}

export default withRouter(AppWrapper);

 */