import axios from 'axios';

export default class AziendaService {

    getAziende() {
        return axios.get('/api/azienda').then(res => res.data);
    }

    saveAzienda(azienda) {
        return axios.post('/api/azienda', azienda).then(res => res.data);
    }

    getAzienda(id){
        return axios.get(`/api/azienda/${id}`).then(res => res.data);
    }

    updateAzienda(azienda){
        return axios.put(`/api/azienda/${azienda.id}`, azienda).then(res => res.data);
    }

}
