import React, { useState } from 'react';

import {Page} from "../../components/Page";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {AdvancedUpload} from "../../components/form/AdvancedUpload";
import axios from "axios";
import Loading from "../../components/Loading";
import {TableWithSidebarFilters} from "../../components/TableWithSidebarFilters";
export const Contratti = () => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadedFileID, setUploadedFileID] = useState(null);
    const fields = {
        codiceContratto : {
            sortable: true,
            header : "Codice Contratto",
            filter : {
                type: "text" // text, list, range
            },
            column:true
        },
        codiceTratta : {
            sortable: true,
            header : "Codice Tratta",
            filter : {
                type: "text" // text, list, range
            },
            column:true

        },
        descrizione : {
            header : "Descrizione",
            filter : {
                placeholder : "Descrizione...",
                type: "text" // text, list, range
            },
            column:true
        },
        used : {
            header : "Utilizzato",
            sortable: true,
            filter : {
                type: "list", // text, list, range
                listElements: [
                    {label:"Si", value:true},
                    {label:"No", value:false},
                ]
            },
            renderer: (rowData,table) => {
                return(
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                            {rowData.used ? "SI" : "NO"}
                        </div>
                    </React.Fragment>
                )
            },
            column:true
        },

        puntoPartenza: {
            header : "Punto di Partenza",
            renderer: (rowData,table) => {
                return(
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                            <i>Comune:</i> {rowData.puntoPartenza.comune}<br/>
                            <i>Provincia:</i> {rowData.puntoPartenza.provincia}<br/>
                            <i>Regione:</i> {rowData.puntoPartenza.regione}
                        </div>
                    </React.Fragment>
                )
            },
            column:true
        },
        lunghezza : {
            header : "Lunghezza (mt)",
            sortable: true,
            renderer: (rowData,table) => {
                return(
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                            {rowData.lunghezza.toLocaleString("it")}
                        </div>
                    </React.Fragment>
                )
            },
            column:true
        }
    }
    const onChange = (f) =>{

        if(f.length>0) {
            setUploadedFileID(f[0].fileID)
        }else{
            setUploadedFileID(null)
        }

    }
    const doImport = (f) =>{
        if(uploadedFileID != null){
            setLoading(true);
            axios.put("/api/contratti/import/" + uploadedFileID ).then(() => {
               setLoading(false);
               alert("Contratti importati correttamente!")
                window.location.reload();
            })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    alert("Errore!")
                })


        }
    }

    const dialogFooter = (
        <div>
            <Button label="Annulla" icon="pi pi-times" onClick={()=>setDialogVisible(false)} loading={loading} />
            <Button label="Import" className="p-button-success" icon="pi pi-times" onClick={()=>doImport()} loading={loading} />

        </div>
    );

    return (
        <Page title="Gestione Contratti" subtitle="Questa pagina consente di gestire i contratti attuativi importati da GEO4WIP" >

            <Button label={"Importa contratti da Excel"}  onClick={()=>setDialogVisible(true)}/>
            <TableWithSidebarFilters endpoint={"/api/contratto"} fields={fields} pratica={true} sla={false}/>

            <Dialog header="Header" visible={dialogVisible} style={{ width: '50vw' }} footer={dialogFooter} onHide={() => setDialogVisible(false)}>
                {loading && <Loading />}
                {!loading && <AdvancedUpload endpoint="/api/file/upload"
                                deleteEndpoint={"/api/file"}
                                label="File Excel"
                                name="files"
                                onChange={onChange}
                                multiple={false} auto={true}
                                withController={false}/> }
            </Dialog>

        </Page>
    );
}