import React, {useContext, useEffect, useState} from 'react';
import { MultiSelect } from 'primereact/multiselect';
import axios from "axios";
import PropTypes from "prop-types";
import {classNames} from "primereact/utils";
import {FormContext, FormItemContext} from "../../hooks/FormContext";
import {CondionalControllerWrapper} from "../../utils/CondionalControllerWrapper";


export const AjaxDropdownMultipleSelect = (props) => {
    const {control} = useContext(FormContext) || {control: {}};
    return (
        <CondionalControllerWrapper name={props.name} wrap={props.withController} control={control}>
            <FormItemContext.Consumer>
                {field => <AjaxDropdownMultipleSelectInner {...props} />}
            </FormItemContext.Consumer>
        </CondionalControllerWrapper>
    )
};

export const AjaxDropdownMultipleSelectInner = (props) => {
    const initValue = [];
    const {errors} = useContext(FormContext) || {
        register: () => {
        }, control: {}, errors: {}
    };
    const field = useContext(FormItemContext) || {field: {}}
    const [value, setValue] = useState(initValue);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);


    const applyValue = (newVal) => {

        if(Array.isArray(newVal)){
            setValue(newVal)
            return newVal;
        }else{
            setValue(initValue);
            return initValue;
        }
    }

    useEffect(() => {
        setLoading(true);
        axios.get(props.baseEndpoint, {
            params: {
                page: 0,
                size: props.maxResults
            }

        }).then((res) => {
            setLoading(false);
            setItems(res.data);
        }).catch((err) => {
            setLoading(false);
            setItems([]);
        })



    }, []);



    useEffect(() => {
        if(props.withController){
            applyValue(field.value);
        }else{
            applyValue(props.value);
        }


    },[props.value, field.value]);



    const change = (e) => {
        let newVal = applyValue(e.value);
        if (props.withController) {
            field.onChange(newVal);
        }
        props.onChange(newVal);

    }


    return (
        <div className="p-field">
            <label htmlFor={props.name}
                   className={classNames("p-d-block", {'p-error': errors[props.name]})}>{props.label}</label>



            <MultiSelect
                disabled={loading}
                value={value}
                options={items}
                onChange={change}
                optionLabel={props.nameField} filter showClear
                filterBy={props.nameField}
                placeholder={props.placeholder}
                className={classNames({'p-invalid': errors[props.name]})}

            />

            <small className="p-error" style={{display: "block"}}>{errors[props.name]?.message}</small>
        </div>

    )



};
AjaxDropdownMultipleSelect.propTypes = {
    baseEndpoint: PropTypes.string.isRequired,
    itemTemplate: PropTypes.func,
    valueTemplate: PropTypes.func,
    maxResults: PropTypes.number,
    onChange: PropTypes.func,
    nameField: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    withController: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    label : PropTypes.string,


}
AjaxDropdownMultipleSelect.defaultProps = {
    baseEndpoint: "/",
    maxResults: 20,
    value: null,
    withController: true,
    placeholder: "Seleziona ...",
    onChange: () => {
    },


}