export default function Loading() {
    return(
        <ul className="loader">
            <li className="dot-a"></li>
            <li className="dot-b"></li>
            <li className="dot-c"></li>
            <li className="dot-d"></li>
            <li className="dot-e"></li>
            <li className="dot-f"></li>
            <li className="dot-g"></li>
            <li className="dot-h"></li>
            <li className="dot-i"></li>
        </ul>
    )
}