import React, { useContext, useEffect, useState } from 'react';
import { HasRole } from "../../utils/HasRole";
import { AjaxAutocomplete } from "../../components/form/AjaxAutocomplete";
import { Button } from 'primereact/button';
import * as yup from "yup";
import { PageAsForm } from "../../components/PageAsForm";
import { AjaxDropdown } from "../../components/form/AjaxDropdown";
import { Textarea } from "../../components/form/Textarea";
import { AdvancedUpload } from "../../components/form/AdvancedUpload";
import axios from "axios";
import { ToastContext } from "../../hooks/UtilsContext";
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { SelectButton } from "primereact/selectbutton";
import { IconWithText } from '../../components/generic/IconWithText';

export const PraticaCreate = () => {

    const [submitting, setSubmitting] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [praticaID, setPraticaID] = useState(null);
    const [formReset, setFormReset] = useState(false);
    const { showWarnMessage } = useContext(ToastContext);
    const [azienda, setAzienda] = useState(null);
    const history = useHistory();
    const [sla, setSla] = useState([{}]);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [position, setPosition] = useState('center');
    const [value, setValue] = useState(null);

    const options = [
        { name: 'Visualizza/modifica SLA', value: true },
    ];

    const itemTemplate = (option) => {
        return <>
            {option.value &&
                <IconWithText icon="info-circle" text={option.name} textBold={true} />}
        </>;
    }

    const dataTableFuncMap = {
        'sla1': setSla,
    };

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    function isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str || str === "0") {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        let n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }

    const onEditorValueChange = (productKey, props, e) => {
        var value = e.target.value;
        let updatedProducts = [...props.value];
        if(isPositiveInteger(value)){
            updatedProducts[props.rowIndex][props.field] = value;
            dataTableFuncMap[`${productKey}`](updatedProducts);
        }else{
            e.preventDefault();
        }
    }

    const inputTextEditor = (productKey, props, field) => {
        return <InputNumber keyfilter='pint' value={props.rowData[field]} onValueChange={(e) => onEditorValueChange(productKey, props, e)} required={true} />;
    }

    const scadenzaEditor = (productKey, props) => {
        return inputTextEditor(productKey, props, 'scadenza');
    }

    const schema = yup.object().shape({

        contratto: yup.object().required("Campo Obbligatorio").nullable(),
        azienda: yup.object().required("Campo Obbligatorio").nullable(),
        tipoPratica: yup.object().required("Campo Obbligatorio").nullable(),
        note: yup.string(),
        files: yup.array(),
    });

    useEffect(() => {
        axios.get("api/configurazione-slas").then(e => setSla(e.data))
        return () => {
            setSla([{}]);
        };


    }, []);


    const onSubmit = async (data) => {
        data.configurazioneSlaDTOS = sla;
        setAzienda(null);
        axios.post("/api/pratica", data)
            .then((r) => {
                setPraticaID(r.data.id);
                setSubmitting(false);
                setShowMessage(true);
                setFormReset(true);
                setFormReset(false);
            })
            .catch(e => {
                setSubmitting(false);
                showWarnMessage("Attenzione", "Impossibile creare la pratica!");
            });
    };
    const newPratica = () => {
        setFormReset(true);
        setShowMessage(false);
    };
    const gotoList = () => {
        setShowMessage(false)
        history.push("/app/pratica/list");
    };
    const gotoDetail = () => {
        setShowMessage(false)
        history.push("/app/pratica/dettaglio/" + praticaID)

    };

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Chiudi" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    function getAzienda(data) {
        if (data !== null && data.aziendaAggiudicatrice !== undefined) {
            setAzienda(data.aziendaAggiudicatrice);
        }
    }

    const dialogFooter = <div className="p-d-flex p-jc-center">
        <Button label="Crea Nuova Pratica" className="p-button-help" autoFocus onClick={newPratica} />
        <Button label="Vai ad Elenco Pratiche" className="p-button-success" autoFocus onClick={gotoList} />
        <Button label="Vai a Dettaglio Pratica" className="p-button-info" autoFocus onClick={gotoDetail} />
    </div>;

    const footer = (<>
        <Button type="submit" label="Crea Pratica" loading={submitting} icon="pi pi-check"
            style={{ marginRight: '.25em', float: "right" }} />
        <Button label="Annulla" icon="pi pi-times" loading={submitting} className="p-button-secondary" onClick={() => history.goBack()} />
    </>);

    return (


        <HasRole roles={["ROLE_OWNER", "ROLE_ADMIN"]} mode={"ONE"} showPanel={true}>
            <PageAsForm title="Crea e Assegna nuova pratica di manutenzione" reset={formReset}
                subtitle="Questa pagina consente di creare una nuova pratica per la gestione della manutenzione"
                onSubmit={onSubmit} schema={schema} footer={footer}>
                <AjaxDropdown
                    withController={true}
                    name="tipoPratica" label="Seleziona una tipologia di Manutenzione*"
                    nameField="name"
                    cache={false}
                    baseEndpoint="/api/TipoPratica"
                />
                <AjaxAutocomplete
                    withController={true}
                    name="contratto" label="Seleziona un contratto Attuativo*"
                    nameField="descrizione"
                    baseEndpoint="/api/contratto/search/"
                    itemTemplate={contrattoItemTemplate}
                    onChange={(value) => getAzienda(value)}
                />

                <AjaxDropdown
                    withController={true}
                    name="azienda" label="Seleziona un'azienda Appaltatrice*"
                    cache={false}
                    value={azienda}
                    nameField={"denominazione"}
                    baseEndpoint="/api/azienda"
                    onChange={(value) => setAzienda(value)}
                />

                <Textarea withController={true} name="note" label="Descrizione/Note sull'intervento" />

                <SelectButton value={value} options={options} onChange={(e) => setValue(e.value)}
                    itemTemplate={itemTemplate} className="p-button-outlined" onChange={() => onClick('displayBasic')} /><br />

                <Dialog visible={displayBasic} style={{ width: '50vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="card">
                        <h1 style={{ textAlign: 'center' }}>GESTIONE SLA</h1>
                        <DataTable value={sla} editMode="cell" className="editable-cells-table">
                            <Column field="taskName" header="Task name"></Column>
                            <Column field="scadenza"  header="Scadenza (gg)" editor={(props) => scadenzaEditor('sla1', props)}></Column>
                        </DataTable>

                    </div>
                </Dialog>

                <AdvancedUpload endpoint="/api/file/upload"
                    deleteEndpoint={"/api/file"}
                    label="Aggiungi files"
                    name="files"
                    multiple={true} auto={true}
                    withController={true} />
                <br />

                <br /><p>I campi contrassegnati con * sono obbligatori.</p>
            </PageAsForm>
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter}
                showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '80vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Crea nuova pratica di manutenzione</h5>
                    <p style={{ lineHeight: 1.5, textAlign: "center" }}>
                        La pratica di manutenzione è stata creata correttamente!
                    </p>
                </div>
            </Dialog>
        </HasRole>
    )
}

const contrattoItemTemplate = (item) => {
    return (
        <div>
            <b>{item.descrizione}</b> <br />
            Tratta: <b>{item.codiceTratta}</b><br /> Contratto: <b>{item.codiceContratto}</b>
        </div>
    );
}


