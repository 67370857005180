import React from 'react';

import {HasRole} from "../../utils/HasRole";

import {BasicCrudPage} from "../../components/BasicCrudPage";
import {Input} from "../../components/form/Input";
import * as yup from "yup";


export const AziendaCrud = () => {

    const fields = {

        denominazione : {
            label : "Denominazione",
            tableRenderer: null,
            schema : yup.string().required("Campo Obbligatorio"),
            formComponent : Input,
            formComponentProps : {

            }
        },
        indirizzo : {
            label : "Indirizzo",
            tableRenderer: null,
            schema : yup.string(),
            formComponent : Input,
            formComponentProps : {

            }
        },
        piva : {
            label : "Partita IVA",
            tableRenderer: null,
            schema : yup.string().matches(/^$|^[0-9]{11}$/||"","Partita iva non valida!")||yup.string().matches(""),
            formComponent : Input,
            formComponentProps : {

            }
        }

    }

    return (
        <HasRole roles={["ROLE_ADMIN"]} mode={"ALL"} showPanel={true}>
            <BasicCrudPage
                entityName="Azienda"
                title="Gestione Aziende appaltratici"
                subtitle="Questa pagina consente di gestire le aziende appaltatrici"
                endpoint="/api/azienda"
                fields={fields}
                csv={false}
            />

        </HasRole>
    )
}

