import React, {useState, useReducer, useContext, useRef} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import useAuth from './../hooks/useAuth';
import {Message} from 'primereact/message';
import {UserContext} from "../hooks/UserContext";
import {useHistory} from 'react-router-dom';
import {confirmDialog} from "primereact/confirmdialog";
import {getGravatar} from "../utils/Utils";
import axios from "axios";
import {Toast} from "primereact/toast";
const formReducer = (state, event) => {
    return {
        ...state,
        [event.target.name]: event.target.value
    }
}


export const Login = () => {
    const toast = useRef(null);
    const history = useHistory();
    const [checked, setChecked] = useState(false);
    const [passwordForm, setPasswordForm] = useState(false);

    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting] = useState(false);
    const {loginUser, error, logoutUser} = useAuth();
    const {user} = useContext(UserContext);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);
        if(passwordForm){
            axios.post('/api/account/reset-password/init', formData.email, { headers: { ['Content-Type']: 'text/plain'}}).then((res) => {
                setSubmitting(false);
                toast.current.show({severity:'info', summary: "Info", detail:"Richiesta di reset password inviata, controlla la mail", life: 3000});
                setTimeout(() => {
                    history.push("/")
                }, 3000);
            }).catch((err) => {
                setSubmitting(false);
                toast.current.show({severity:'error', summary: err.response.data.status, detail:err.response.data.detail, life: 3000});
            })
        }else {
            await loginUser({username: formData.username, password: formData.password, rememberMe: checked});
        }
        setSubmitting(false);

    }
    var h = window.innerHeight;
    var w = window.innerWidth;


    const bgStyle = {
        background: "url('https://picsum.photos/" + w + "/" + h + "/?random') no-repeat",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center"
    }
    const confirm = () => {
        confirmDialog({
            message: 'Sei sicuro di voler uscire?',
            header: 'Attenzione',
            icon: 'pi pi-exclamation-triangle',
            accept: () => logoutUser(),
            reject: () => ({})
        });
    }
    const gravatar = user != null ? getGravatar(user.email, 100) : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";
    return (

        <form onSubmit={handleSubmit}>
            <Toast ref={toast} />
            <div className="login-body" style={bgStyle}>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">

                        {user === null ? (


                            <div className="p-grid">
                                {passwordForm === true ? (<>

                                    <div className="p-col-12 p-sm-12 p-md-12 logo-container">
                                        <h2>Geo4Man</h2>
                                        <span
                                            className="guest-sign-in">Inserisci la tua mail per resettare la password. </span>
                                    </div>
                                    <div className="p-col-12 username-container">
                                        <label>Email</label>
                                        <div className="login-email">
                                            <InputText id="email" type="email" name="email"
                                                       onChange={setFormData}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-sm-6 p-md-6">

                                        <Button label="Annulla" type={"button"}  className="p-button-warning" onClick={()=>{setPasswordForm(false)}} icon="pi pi-times" loading={submitting}/>
                                    </div>
                                    <div className="p-col-12 p-sm-6 p-md-6">

                                        <Button label="Reset Password" icon="pi pi-check" loading={submitting}/>
                                    </div>
                                    {error && (
                                        <div className="p-col-12">
                                            <Message severity="error" text={error}></Message>
                                        </div>
                                    )
                                    }
                                    </>) : (
                                    <>
                                        <div className="p-col-12 p-sm-12 p-md-12 logo-container">
                                            <h2>Geo4Man</h2>
                                            <span
                                                className="guest-sign-in">Benvenuto, accedi per continuare. </span>
                                        </div>
                                        <div className="p-col-12 username-container">
                                            <label>Username</label>
                                            <div className="login-input">
                                                <InputText id="input" type="text" name="username"
                                                           onChange={setFormData}/>
                                            </div>
                                        </div>
                                        <div className="p-col-12 password-container">
                                            <label>Password</label>
                                            <div className="login-input">
                                                <InputText type="password" name="password" onChange={setFormData}/>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-sm-6 p-md-6 rememberme-container">
                                            <Checkbox checked={checked} onChange={e => setChecked(e.checked)}/>
                                            <label> Ricordami</label>
                                        </div>

                                        <div className="p-col-12 p-sm-6 p-md-6 forgetpassword-container">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setPasswordForm(true)

                                            }} className="forget-password">Password Dimenticata?</a>
                                        </div>
                                        <div className="p-col-12 p-sm-6 p-md-6">
                                            <Button label="Accedi" icon="pi pi-check" loading={submitting}/>
                                        </div>
                                        {error && (
                                            <div className="p-col-12">
                                                <Message severity="error" text={error}></Message>
                                            </div>
                                        )
                                        }
                                    </>
                                )}
                            </div>
                        ) : (

                            <div className="p-grid ">

                                <div className="p-col-xl-4 p-col-sm-12 p-col-align-center ">

                                    <img src={gravatar} alt="roma-layout" className="avatar "/>

                                </div>
                                <div className="p-col-xl-8  p-col-sm-12 p-col-align-center">
                                    <h4>{user.firstName} {user.lastName}</h4>
                                    <p>{user.email}</p>
                                </div>

                                <div className="p-col-12 p-sm-12 p-md-12">
                                    <Button label="Vai alla home" icon="pi pi-home"
                                            onClick={() => history.push("/app")}/>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-12">
                                    <Button label="Logout" icon="pi pi-fw pi-sign-out" className={"p-button-danger"}
                                            onClick={confirm}/>
                                </div>

                            </div>


                        )}
                    </div>
                </div>
            </div>
        </form>
    )
}