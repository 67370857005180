import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import {ProfileEditForm} from "../../components/user/ProfileEditForm";
import {ProfileChangePassword} from "../../components/user/ProfileChangePassword";

import {Page} from "../../components/Page";


export const UserProfile = () => {

    return (
        
            <Page title="Impostazioni Profilo"
                        subtitle="Questa pagina consente di modificare il proprio profilo e la propria password."
                       >

                <TabView>
                    <TabPanel header="Informazioni Personali">
                        <ProfileEditForm />
                    </TabPanel>
                    <TabPanel header="Password">
                        <ProfileChangePassword />
                    </TabPanel>
                </TabView>

            </Page>

        
    )
}

