import React, { useState, useEffect} from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { DettaglioContratto } from "../pratiche/DettaglioContratto";
import { DettaglioAzienda } from "../pratiche/DettaglioAzienda";
import { reportsMock } from '../../utils/Utils';

export const DettaglioReport = () => {

    const [report, setReport] = useState(null);
    const [reports, setReports] = useState(reportsMock);
    const history = useHistory();
    const id = useParams();


    useEffect(() => {
        if (reports) {
            reports.map(prat => {
                if (prat.id === id.id) {
                    setReport(prat);
                }
            })
        }
    }, []);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                {report != null &&
                    <>
                        <h2>DETTAGLIO REPORT {report.id}</h2>
                        <div className="card">
                            <DettaglioContratto id={report.idContratto} />
                            <DettaglioAzienda azienda={report.azienda} />
                            <div className="card">
                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <h4>ID</h4>
                                        <p>{report.id}</p>
                                    </div>
                                    <div className="p-col-6">
                                        <h4>Nome task</h4>
                                        <p>{report.nomeTask}</p>
                                    </div>
                                    <div className="p-col-6">
                                        <h4>Status</h4>
                                        <p>{report.status}</p>
                                    </div>
                                    <div className="p-col-6">
                                        <h4>Definition Id</h4>
                                        <p>{report.definitionID}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <Button label="Indietro" icon="pi pi-arrow-circle-left" onClick={() => history.goBack()} className="p-button-raised p-button-lg" />
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );

}