import * as PropTypes from "prop-types";
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import { IconWithText } from "../generic/IconWithText";
import './TabViewCustom.scss';
import { Timeline } from 'primereact/timeline';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from "axios";
import React, { useEffect, useState } from "react";
import convertHistoryItem from './PraticaHistoryStates';
import { DateText } from "../generic/DateText";
import { Icon } from "../generic/Icon";
import { Button } from "primereact/button";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
// import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import "./styles.scss";
import { tr } from "yup-locales";


export const PraticaDetail = (props) => {


    const [image, setImage] = useState(null);
    const [imageMap, setImageMap] = useState(null)
    const [events, setEvents] = useState([]);
    const [zoom, setZoom] = useState(17);
    useEffect(() => {

        if (props.data?.processInstance) {
            axios.get("/api/task/diagram/" + props.data.processInstance).then((res) => {
                setImage("data:image/svg+xml;base64," + btoa(res.data));
            })
        }
        if (props.data?.id) {
            axios.get("/api/pratica/" + props.data.id + "/history").then((res) => {

                // let evts = res.data.map((h,index)=>{

                //     return  convertHistoryItem(h,index);

                // });
                setEvents(res.data);


            })
        }

    }, [props.data])

    useEffect(() => {
        ;
    }, [])


    const customizedMarker = (item0) => {
        const item = convertHistoryItem(item0);
        return (

            <span className="custom-marker" >
                <Icon icon={item.icon} iconColor={item.color} />
            </span>


        );
    };


    const customizedContent = (item0) => {
        const item = convertHistoryItem(item0);

        return (
            <div key={item.key} className="p-p-2 p-pr-3 p-pl-3" style={{ border: "solid 1px #CCCCCC", borderRadius: "10px" }}>
                <h5 className="p-mb-0">{item.status}</h5>
                <div>{item.subtitle}</div>
                {item.meta}
            </div>
        );
    };

    const fileUserBody = (rowData) => {
        //return (<>{rowData.userFullName}<br /><DateText value={rowData.createdDate} /></>)
        try {
            var utcSeconds = rowData.createdDate.epochSecond;
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d = d.setUTCSeconds(utcSeconds);
            if (utcSeconds != null) {
                return (<>{rowData.userFullName}<br /><DateText value={d} /></>);
            }
            else {
                return (<>{rowData.userFullName}<br /><DateText value={rowData.createdDate} /></>);
            }
        } catch { }
    }
    const fileNameBody = (rowData) => {
        return <Button title={rowData.filename} onClick={() => downloadFile("/api/file/download/" + rowData.id)} label={<div className="p-text-nowrap p-text-truncate" style={{ width: "20vh" }}>{rowData.filename}</div>} className="p-button-link p-text-nowrap p-text-truncate" />
    }

    const downloadFile = (url) => {
        axios.get(url)
            .then(response => {
                var arrBuffer = base64ToArrayBuffer2(response.data);
                const url = window.URL.createObjectURL(new Blob([arrBuffer]), { type: response.headers["content-type"] });
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.headers['content-disposition'].split('attachment; filename=')[1]);
                document.body.appendChild(link);
                link.click();
            });
    }


    function base64ToArrayBuffer2(data) {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

    /*const downloadFileZip = (url) => {
        axios.post(url, ['61547a71b8f5f00b35224364', '6155aae93598d200eb0763a9'])
            .then(response => {
                onDownloadZipFile(response.data);
            });
    }*/

    const viewByte = (id) => {
        axios.get("/api/file/download/" + id).then(res => setImageMap(res.data))
    }

   

    const coordinate = () => {

        return ( 
            <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={true} removeOutsideVisibleBounds={true} spiderLegPolylineOptions={true}>
                {renderMarker()}
            </MarkerClusterGroup>)
    }

    function renderMarker(){
        let coordinateVar = props.data.immagineGeo;
        return coordinateVar.map(x => {
            //viewByte(x.fileId)
            return (
                <>
                
                    <Marker position={[x.lat, x.lng]} icon={greenIcon}>
                        
                        <Popup closeButton={false}>
                            <img onClick={() => downloadFile("/api/file/download/" + x.fileId)} alt="downloadImageGeo"src={`data:image/jpeg;base64,${x.file}`} width="150px" />
                        </Popup>
                        
                    </Marker>
                   
                </>
            )
        })
    }

    /*
        function onDownloadZipFile(base64Data) {
    
            var arrBuffer = base64ToArrayBuffer2(base64Data);
            var newBlob = new Blob([arrBuffer], { type: 'application/zip' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            var data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            document.body.appendChild(link); //required in FF, optional for Chrome
            link.href = data;
            link.download = 'prova_zip';
            link.click();
            window.URL.revokeObjectURL(data);
            link.remove();
        }
    */
    var greenIcon = L.icon({
        iconUrl: '/assets/layout/images/camera.svg.png',

        iconSize:     [50, 70], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [16, 35], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [-3, -26] // point from which the popup should open relative to the iconAnchor
    });

    return (
        <>
            {!props.data?.id && (
                <h1>Nessuna Pratica selezionata</h1>
            )}

            {props.data?.id && (
                <div className="tabview-detail" style={props.stye}>

                    <TabView className="tabview-custom">
                        <TabPanel header="Dati generali" leftIcon="pi pi-info-circle">
                            <div className="p-grid" >
                                <Panel header="Contratto Attuativo" className="p-col-12 p-md-12 p-lg-12 p-sm-12 " toggleable collapsed={props.collapsed}    >
                                    <div className="p-grid" >
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-6">
                                            <IconWithText icon="file-contract" text="Contratto Attuativo" textBold={true} />
                                            <p>{props.data.contratto.codiceContratto}</p>
                                        </div>
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-6">
                                            <IconWithText icon="route" text="Codice Tratta" textBold={true} />
                                            <p >
                                                {props.data.contratto.codiceTratta}<br />
                                                Lunghezza: {props.data.contratto.lunghezza}mt
                                            </p>
                                        </div>
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-6">
                                            <IconWithText icon="map-marked-alt" text="Punto di Partenza" textBold={true} />
                                            <p>
                                                Regione: {props.data.contratto.puntoPartenza.regione}<br />
                                                Provincia: {props.data.contratto.puntoPartenza.provincia}<br />
                                                Comune: {props.data.contratto.puntoPartenza.comune}<br />
                                            </p>

                                        </div>
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-6">
                                            <IconWithText icon="info-circle" text="Descrizione" textBold={true} />
                                            <p >{props.data.contratto.descrizione}</p>
                                        </div>
                                    </div>
                                </Panel>
                                <Panel header="Dati Pratica" className="p-col-12 p-md-12 p-lg-12 p-sm-12" toggleable collapsed={props.collapsed} >
                                    <div className="p-grid" >
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-6">
                                            <IconWithText icon="building" text="Azienda Appaltatrice" textBold={true} />
                                            <p>{props.data.azienda.denominazione}</p>
                                        </div>
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-6">
                                            <IconWithText icon="file-powerpoint" text="Tipologia di Pratica" textBold={true} />
                                            <p>{props.data.tipoPratica.name}</p>
                                        </div>
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-6">
                                            <IconWithText icon="paragraph" text="Descrizione Intervento" textBold={true} />
                                            <p>{props.data.descrizione}</p>
                                        </div>
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-6">
                                            <IconWithText icon="thermometer-three-quarters" text="Stato Attuale" textBold={true} />
                                            <p>{props.data.currentTaskName}</p>
                                        </div>
                                    </div>

                                </Panel>
                                <Panel header="Dettagli SLA" className="p-col-12 p-md-12 p-lg-12 p-sm-12" toggleable collapsed={props.collapsed} >
                                    <div>
                                        <div className="card">
                                            <DataTable value={props.data.configurazioneSla} responsiveLayout="scroll">
                                                <Column field="taskName" header="Nome task"></Column>
                                                <Column field="scadenza" header="Scadenza (gg)"></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </Panel>
                                <Panel header="Mappa immagini georeferenziate" className="p-col-12 p-md-12 p-lg-12 p-sm-12" toggleable collapsed={props.collapsed} >
                                    <div >
                                        
                                        {props.data.immagineGeo[0] !== undefined ? (
                                            <MapContainer center={[props.data.immagineGeo[0].lat, props.data.immagineGeo[0].lng]} zoom={zoom} maxZoom={18} minZoom={10} scrollWheelZoom={false} className="markercluster-map">
                                                <TileLayer
                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                {/*coordinate()*/}
                                                <MarkerClusterGroup spiderfyOnMaxZoom={true} showCoverageOnHover={true} removeOutsideVisibleBounds={true} spiderLegPolylineOptions={true}>
                                                    {renderMarker()}
                                                </MarkerClusterGroup>


                                            </MapContainer>
                                            
                                        ) :
                                            (
                                                <p>Nessuna immagine georeferenziata da mostrare</p>
                                            )}


                                    </div>

                                </Panel>
                                <Panel header="Files" className="p-col-12 p-md-12 p-lg-6 p-sm-12" toggleable collapsed={props.collapsed} >


                                    <DataTable value={props.data.files} className="p-datatable-sm" emptyMessage={"Nessun file caricato"}>
                                        <Column body={fileNameBody} header="File"></Column>
                                        <Column field="size" header="Dimensione"></Column>
                                        <Column header="Caricamento" body={fileUserBody}></Column>

                                    </DataTable>






                                </Panel>
                                <Panel header="Note" className="p-col-12 p-md-12 p-lg-6 p-sm-12" toggleable collapsed={props.collapsed} >
                                    {/* <div className="p-grid" >
                                        <div className="p-col-6 p-md-6 p-lg-3 p-xl-3 p-sm-12"> */}
                                    <DataTable value={props.data.notes} className="p-datatable-sm" emptyMessage={"Nessuna nota caricata"}>
                                        <Column header="Caricamento" body={fileUserBody}></Column>
                                        <Column field="note" header="Testo"></Column>
                                    </DataTable>

                                    {/* </div>
                                    </div> */}
                                </Panel>

                            </div>
                        </TabPanel>
                        <TabPanel header="Cronologia" leftIcon="pi pi-clock">
                            <div className="p-grid p-justify-center" >
                                <Panel header="Diagramma" className="p-col-12 p-md-12 p-lg-12 p-xl-12 p-sm-12 p-text-center" toggleable collapsed={props.collapsed}  >
                                    {image !== "data:image/svg+xml;base64," ? (
                                        <img alt="diagramma" src={image} style={{ width: "75%" }} />
                                    ) : (
                                        <p>Diagramma non disponibile o processo terminato.</p>
                                    )}
                                </Panel>
                                <Panel header="Timeline" className="p-col-12 p-md-12 p-lg-12 p-xl-12 p-sm-12" toggleable collapsed={props.collapsed}  >
                                    <div className="timeline-demo">
                                        <Timeline value={events} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                                    </div>
                                </Panel>

                            </div>
                        </TabPanel>
                    </TabView>





                    {/*
                <div className="p-col-12 p-md-4 p-lg-4">
                    <div className="timeline card card-w-title">
                        <h5>Timeline</h5>
                        <ul>
                            <li>
                                <i className="pi pi-image"></i>
                                <span className="timeline-event-title">Uploaded Images</span>
                                <span className="timeline-event-time">15m</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <img src="/assets/layout/images/dashboard/image-1.jpg" alt="roma" />
                                    </div>
                                    <div className="p-col-6">
                                        <img src="/assets/layout/images/dashboard/image-2.jpg" alt="roma" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <i className="pi pi-star"></i>
                                <span className="timeline-event-title">Favorites</span>
                                <span className="timeline-event-time">1h</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            </li>
                            <li>
                                <i className="pi pi-users"></i>
                                <span className="timeline-event-title">Meeting</span>
                                <span className="timeline-event-time">2h</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            </li>
                            <li>
                                <i className="pi pi-money-bill"></i>
                                <span className="timeline-event-title">Payment Received</span>
                                <span className="timeline-event-time">3h</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            </li>
                        </ul>
                    </div>
                </div>
                */}
                </div>

            )}

        </>
    )

}

PraticaDetail.propTypes = {
    data: PropTypes.object,
    collapsed: PropTypes.bool
};
PraticaDetail.defaultProps = {
    data: {},
    collapsed: false
}
