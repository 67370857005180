import {AUTH_TOKEN_KEY} from './Costants';
import {clearAuthToken} from "../hooks/useAuth";

export const onRequestSuccess = config => {
    const token = window.localStorage.getItem(AUTH_TOKEN_KEY) ||  window.sessionStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};

export const onResponseSuccess = response => response;

export const onResponseError = err => {

    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
        clearAuthToken();
    }
    return Promise.reject({ ...err })
};


