import React from 'react';

import {HasRole} from "../../utils/HasRole";

import {BasicCrudPage} from "../../components/BasicCrudPage";
import {Input} from "../../components/form/Input";
import * as yup from "yup";
import {AjaxDropdownMultipleSelect} from "../../components/form/AjaxDropdownMultipleSelect";

export const SquadraCrud = () => {

    const fields = {

        nome : {
            label : "Nome",
            tableRenderer: null,
            schema : yup.string().required("Campo Obbligatorio"),
            formComponent : Input,
            formComponentProps : {

            }
        },
        // operatori : {
        //     label : "Operatori",
        //     tableRenderer(rowData,table) {
        //         return (
        //             <React.Fragment>
        //                 <span className="p-column-title">Operatori</span>
        //                 {rowData.operatori && rowData.operatori.length > 0 && 
        //                     <ul style={{display: "inline-grid"}}>
        //                         {rowData.operatori.map((r, i) => {
        //                         return (<li key={i}>{r.fullName}</li>)
        //                     })}
        //                 </ul>}
        //             </React.Fragment>
        //         );
        //     },
        //      schema :  yup.array().min(1,"Selezionare almeno ${min} operatore.").required("Campo Obbligatorio"),
        //      formComponent : AjaxDropdownMultipleSelect,

        //      formComponentProps : {
        //          nameField: "fullName",
        //          baseEndpoint:"api/user/worker"
        //      }
        //  },
         operatori : {
            label : "Operatori",
            tableRenderer(rowData,table) {
                return (
                    <React.Fragment>
                        <span className="p-column-title">Operatori</span>
                        <ul style={{display: "inline-grid"}}>
                            {rowData.operatori.map((r, i) => {
                                return (<li key={i}>{r.fullName}</li>)
                            })}
                        </ul>
                    </React.Fragment>
                );
            },
            
            schema :  yup.array().min(1,"Selezionare almeno ${min} ruolo.").required("Campo Obbligatorio"),
            formComponent : AjaxDropdownMultipleSelect,

            formComponentProps : {
                nameField: "fullName",
                baseEndpoint:"api/user/worker"
            }
        },



    }
    const onLoad = (data) => {
        return data.map(r=>{
            r.operatori = r.operatori.map(rs=>{
                return {id: rs.id,fullName:rs.fullName, email:rs.email}});
            return r;
        });

    }

    const onSubmit = (data) => {
        data.operatori = data.operatori.map(a=>{return a.id});
        return data;

    }


    return (
        <HasRole roles={["ROLE_CONTRACTOR"]} mode={"ALL"} showPanel={true}>
            <BasicCrudPage
                entityName="Squadra"
                title="Gestione Squadre"
                subtitle="Questa pagina consente di gestire le squadre dell'azienda"
                endpoint="/api/squadra"
                fields={fields}
                onLoad={onLoad}
                onSubmit={onSubmit}
                csvSquadra={true}
                csv={true}
            />

        </HasRole>
    )
}

