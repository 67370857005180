
import React from "react";

import PropTypes from 'prop-types';
import {Controller} from "react-hook-form";
import {FormItemContext} from "../hooks/FormContext";

export const CondionalControllerWrapper = (props) => {


    if (props.wrap) {
        return (
            <Controller
                control={props.control}
                name={props.name}
                render={({field}) => (
                    <FormItemContext.Provider value={field}>
                        {props.children}
                    </FormItemContext.Provider>
                )}
            />
        )
    } else {
        return (
            <>
                {props.children}
            </>
        )
    }


}

CondionalControllerWrapper.propTypes = {

    wrap: PropTypes.bool.isRequired,
    control: PropTypes.object,
    name: PropTypes.string.isRequired
}
CondionalControllerWrapper.defaultProps = {

    wrap: true
}