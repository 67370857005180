import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as PropTypes from "prop-types";

import {FormContext} from "../hooks/FormContext";

export const Form = (props) => {


    const {register,control, handleSubmit,reset,setValue,  formState: {errors}} = useForm(
        {
            resolver: yupResolver(props.schema),
            defaultValues:props.defaultValues
        }
    );

    useEffect(() => {

       if(props.reset){
           reset({
            note: ""
          });
       }
    }, [props.reset]);

    useEffect(() => {
        if(Object.keys(props.values).length === 0 ){
            reset();
        }
        for(let p in props.values){
            setValue(p,props.values[p]);
        }
    }, [props.values]);

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} >

                <FormContext.Provider value={{register,control,errors,reset}}>
                    {props.children}
                </FormContext.Provider>

        </form>
    )
}
Form.propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit : PropTypes.func.isRequired,
    schema : PropTypes.object.isRequired,
    defaultValues: PropTypes.object,
    values:PropTypes.object,
    reset: PropTypes.bool
};
Form.defaultProps = {
    reset:false,
    values: {},
    defaultValues: {}
}

