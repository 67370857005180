
import * as PropTypes from "prop-types";
import {useEffect, useState} from "react";

export const DateText = (props) => {

    const [value, setValue] = useState(null);

    useEffect(() => {

        try {
            var date = new Date(props.value);
            var str =
                ("00" + date.getDate()).slice(-2)
                + "/" + ("00" + (date.getMonth() + 1)).slice(-2)
                + "/" + date.getFullYear() + "@"
                + ("00" + date.getHours()).slice(-2) + ":"
                + ("00" + date.getMinutes()).slice(-2);
            setValue(str);
        }catch (e) {

        }
    },[props.value])

    return (<>{value}</>)
}
DateText.propTypes = {
    value: PropTypes.string.isRequired,


};
DateText.defaultProps = {
    value: null

}

