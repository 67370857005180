
import * as PropTypes from "prop-types";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames} from "primereact/utils";
export const IconWithText = (props) => {

    const parentStyle = {
        display: "flex",
        alignItems: "center"
    }


    return (

            <div style={parentStyle}>
                <FontAwesomeIcon icon={props.icon} style={{color: props.iconColor}} size="2x" className={"p-mr-2"}/>
                <span className={classNames({"p-text-bold":props.textBold})} >{props.text}</span>
            </div>

    )
}
IconWithText.propTypes = {
    icon: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
    text: PropTypes.string.isRequired,
    textBold: PropTypes.bool

};
IconWithText.defaultProps = {
    iconColor : "#0f97c7",
    textBold:false
}

