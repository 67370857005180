import React, { useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import * as yup from "yup";
import { Toast } from 'primereact/toast';
import {Form} from "../Form";
import {Password} from "../form/Password";
import axios from "axios";


export const ProfileChangePassword = () => {

    const [showMessage, setShowMessage] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const toast = useRef(null);


    const schema = yup.object().shape({
        currentPassword: yup.string().required("Campo Obbligatorio"),
        newPassword: yup.string().required("Campo Obbligatorio").min(4, 'Lunghezza minima di 4 caratteri'),
        confirmPassword: yup.string().required("Campo Obbligatorio").oneOf([yup.ref('newPassword'), null], 'Le password non coincidono'),
    });



    const onSubmit = async (data,e) => {

       setSubmitting(true);
       axios.post('/api/account/change-password',{
           currentPassword : data.currentPassword,
           newPassword: data.newPassword,

       }).then((res) => {
            e.target.reset();
            setShowMessage(true);
            setSubmitting(false);
        }).catch((err) => {
           setSubmitting(false);
           toast.current.show({severity:'error', summary: err.response.data.status, detail:err.response.data.title, life: 3000});
       })



    };

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK"  autoFocus
                                                                       onClick={() => setShowMessage(false)}/></div>;
    return (
        <div>
            <Toast ref={toast} />
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter}
                    showHeader={false} breakpoints={{'960px': '80vw'}} style={{width: '30vw'}}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{fontSize: '5rem', color: 'var(--green-500)'}}></i>
                    <h5>Modifica Password</h5>
                    <p style={{lineHeight: 1.5, textIndent: '1rem'}}>
                        La modifica della password è stata effettuata.
                    </p>
                </div>
            </Dialog>

            <Form onSubmit={onSubmit} schema={schema}>

                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-12 p-lg-6">


                        <Password withController={true} name={"currentPassword"} label="Password corrente*" />
                        <Password withController={true} name={"newPassword"} label="Nuova Password*"/>
                        <Password withController={true} name={"confirmPassword"} label="Conferma Password*"/>

                    </div>
                </div>
                <Button type="submit" label="Modifica Password" loading={submitting} icon="pi pi-check"/>
            </Form>

        </div>
    );
}
