import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Card } from "primereact/card";
import axios from 'axios';




export const DashboardCards = (props) => {
    const [statistiche, setStatistiche] = useState(null);

    useEffect(() => {
        loadStatistiche();
        return () => {
            setStatistiche({});
            };
    }, []);

    function loadStatistiche(){
        axios.get("/api/task/statistiche", []).then( res => {
            if(res.data){
                setStatistiche({ assegnati: parseInt(res.data.taskAssegnati),
                                incarico: parseInt(res.data.taskInCarico),
                                totali: parseInt(res.data.taskTotali)})
            }
        });   


    }
    
    useImperativeHandle(props.innerRef, () => ({
        reload: () => {
            loadStatistiche();
        }
    }));


    return (
        <>
        {statistiche && 
            <>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <Card title={"NUMERO TASK: "+ statistiche.totali} header={(<div className="card-header" style={{background:"green"}}></div>)}/>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <Card title={"TASKS ASSEGNATI: " + statistiche.assegnati} header={(<div className="card-header" style={{background:"green"}}></div>)} />
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <Card title={"TASKS IN CARICO: "+ statistiche.incarico} header={(<div className="card-header" style={{background:"green"}}></div>)} />
                </div>
            </>}
            </>
        
    )

}
