import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router-dom";
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { Chart } from 'primereact/chart';
import { ToastContext } from '../../hooks/UtilsContext';
import { UserContext } from '../../hooks/UserContext';
import { hasOneRole } from '../../utils/Utils';
import { DateText } from '../../components/generic/DateText';
import { TableWithSidebarFilters } from '../../components/TableWithSidebarFilters';
import { PraticaDetail } from '../../components/wf/PraticaDetail';
import {HasRole} from "../../utils/HasRole";


export const MonitoraggioSLA = (props) => {

    const [loading, setLoading] = useState(false);
    const [selectedPratica, setSelectedPratica] = useState({});
    const [showDetail, setShowDetail] = useState(false);
    const { user } = useContext(UserContext);
    const [dataChart, setDataChart] = useState(null);


    useEffect(() => {
        axios.get("/api/task/sla").then( res => {
            setDataChart(res.data);
        })
    }, []);




    const openDetail = (rowData) => {
        setSelectedPratica(rowData);
        setShowDetail(true);
    }

    const statusBodyTemplate = (data) => {
        let badge = badgeSla(data.entityData);
        return (
            <>
                <span className="p-column-title">Status</span>
                {badge && <Tag value={badge.label} className="p-mr-2" size="xlarge" icon={badge.icon} severity={badge.color}></Tag>}
            </>
        );
    };


    function badgeSla(data) {
        let badge = {};
        if (data.praticaState && Object.keys(data.praticaState).length > 0) {
            badge.color = data.praticaState.color;
            badge.label = data.praticaState.label;
            badge.icon = data.praticaState.icon;
            return badge;

        } else if (data.praticaState === null || Object.keys(data.praticaState).length === 0) {
            badge.color = 'secondary';
            badge.label = 'NON DEFINITO';
            badge.icon = "pi pi-exclamation-triangle";
            return badge;
        }

    }

    const assegnatarioBodyTemplate = (rowData) => {
        let pratica = rowData.entityData;
         if(hasOneRole(user, ["ROLE_ADMIN"])) {
            return (
                <React.Fragment>
                    <span className="p-column-title">Assegnatario </span>
                    {pratica &&
                        <>
                            <span>{pratica.contratto != null && pratica.contratto.proprietaInfr}</span>
                        </>
                    }
                </React.Fragment>
            )
        }else if (hasOneRole(user, ["ROLE_OWNER"])) {
            return (
                <React.Fragment>
                    <span className="p-column-title">Assegnatario </span>
                    {pratica &&
                        <>
                            <span>{pratica.azienda.denominazione}</span>
                        </>
                    }
                </React.Fragment>
            )
        }
        else if(hasOneRole(user, ["ROLE_CONTRACTOR"])) {
            return (
                <React.Fragment>
                    <span className="p-column-title">Assegnatario </span>
                    {pratica &&
                        <>
                            <span>{pratica.squadra != null && pratica.squadra.nome}</span>
                        </>
                    }
                </React.Fragment>
            )
        }
    }

    const actionTemplate = (rowData, table) => {

        return (
            <React.Fragment>
                <span className="p-column-title">{table.header}</span>
                <Button loading={loading} icon="pi pi-book" title={"Dettaglio"} data-pr-tooltip={"Dettaglio"} className="p-button-rounded p-mr-1"
                    onClick={() => openDetail(rowData)} />
            </React.Fragment>
        )
    }

    const fields = {
        id: {
            header: "Id",
            filter: {
                type: "text"
            },
            column: true
        },
        "status": {
            header: "Status",
            headerStyle: { width: "120px" },

            renderer: (rowData) => {
                return (
                    statusBodyTemplate(rowData))
            },
            column: true


        },
        assegnatario: {
            header: "Assegnatario",
            renderer: (rowData) => {
                return (assegnatarioBodyTemplate(rowData))
            },
            column: true
        },
        taskName: {
            header: "Attività",
            filter: {
                type: "list", // text, list, range
                listElements: [
                    { label: "Accettazione", value: "Accettazione" },
                    { label: "Assegnazione", value: "Assegnazione" },
                    { label: "Lavorazione", value: "Lavorazione" },
                    { label: "Accettazione Lavorazione", value: "Accettazione Lavorazione" },
                    { label: "Assegnazione Squadra", value: "Assegnazione Squadra" },
                    { label: "Conferma", value: "Conferma" },
                    { label: "Conferma Finale", value: "Conferma Finale" },
                    { label: "Invio Dati", value: "Invio Dati" }
                ]
            },
            column: true
        },
        "entityData.tipoPratica.name": {
            header: "Tipo Pratica",
            filter: {
                field: "tipoPratica.name",
                pratica: true,
                type: "ajax-list",
                nameField: "name",
                baseEndpoint: "/api/TipoPratica"
            },
            column: true
        },
        "entityData.descrizione": {
            header: "Descrizione",
            filter: {
                field: "descrizione",
                pratica: true,
                type: "text"
            },
            column: true
        },
        "entityData.contratto.codiceContratto": {
            header: "Codice Contratto",
            filter: {
                field: "contratto.codiceContratto",
                pratica: true,
                type: "text"
            },
            column: false
        },
        "entityData.contratto.codiceTratta": {
            header: "Codice Tratta",
            filter: {
                field: "contratto.codiceTratta",
                pratica: true,
                type: "text"
            },
            column: false
        },
        "create_time": {
            header: "Data Creazione",
            sortable: true,
            filter: {
                type: "date"
            },
            renderer: (rowData, table) => {
                return (
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                            <DateText value={rowData.creationDate} />
                        </div>
                    </React.Fragment>
                )
            },
            column: true
        },
        contratto: {
            header: "Contratto Attuativo",
            renderer: (rowData, table) => {
                return (
                    <React.Fragment>
                        <span className="p-column-title">{table.header}</span>
                        <div>
                            <b>{rowData.entityData.contratto.descrizione}</b> <br />
                            Tratta: <b>{rowData.entityData.contratto.codiceTratta}</b><br /> Contratto: <b>{rowData.entityData.contratto.codiceContratto}</b>
                        </div>
                    </React.Fragment>
                )
            },
            column: true
        },
        action: {
            header: "Azioni",
            headerStyle: { width: "120px" },
            renderer: actionTemplate,
            column: true

        }

    }


    const dialogFooter = () => {
        return (
            <div>
                <Button label="Chiudi" icon="pi pi-check" onClick={() => setShowDetail(false)} autoFocus />
            </div>
        );
    }

    function doughnutData(value) {
        let dataC = new Array();
        Object.keys(value).map(key =>{
            if((key != 'taskTotali') && (key !== 'taskAssegnati') && (key !== 'taskInCarico')){
                dataC.push(value[key])
            }
        });
        return {
            labels: ['OK', 'IN SCADENZA', 'IN RITARDO'],
            datasets: [
                {
                    data: dataC,
                    backgroundColor: [
                        'rgb(104, 159, 56)',
                        'rgb(251, 192, 45)',
                        'rgb(211, 47, 47)'
                    ]
                }]
        }
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/*<FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />*/}
                <Button label="Export CSV" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const oggi = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var hh = today.getHours();
        var min = today.getMinutes();
        var sec = today.getSeconds();

        if (dd < 10)
            dd = '0' + dd;

        if (mm < 10)
            mm = '0' + mm;

        if (hh < 10)
            hh = '0' + hh;

        if (min < 10)
            min = '0' + min;

        if (sec < 10)
            sec = '0' + sec;

        today = dd + "." + mm + "." + today.getFullYear() + "_" + hh + "." + min + "." + sec;
        return today
    }

    const exportCSV = () => {

        axios.post('api/tasklist/csv/ALL', { responseType: 'blob' }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ELENCOSLA_${oggi()}.csv`);
            document.body.appendChild(link);
            link.click();
        }).catch(res => {
            const reader = new FileReader();
            reader.onload = function () {
                var error = JSON.parse(this.result);
                console.log(error)
            }
            reader.readAsText(res.response.data)
        })
    }

    return (

        <HasRole roles={["ROLE_ADMIN","ROLE_OWNER","ROLE_CONTRACTOR"]} mode={"ONE"} showPanel={true}>
        <div className="p-grid layout-dashboard">
            {dataChart && dataChart['taskTotali']>0 &&

                <div className="p-col-12">
                    <div className="expenses card card-w-title">
                        <h2 style={{ textAlign: 'center' }}>MONITORAGGIO SLA</h2>

                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-lg-12">
                                <div style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}>
                                    <Chart type="doughnut" data={doughnutData(dataChart)} width={"50%"} height='50%' style={{ marginLeft: "auto", marginRight: "auto" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}


            <div className="p-col-12 p-xl-12">
                <div className="card card-w-title global-sales ui-fluid">
                    <h5>Lista SLA</h5>
                    <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                    <TableWithSidebarFilters endpoint="/api/tasklist/filters/ALL" pratica={false} fields={fields}/>

                    <Dialog header="Dettaglio Pratica" visible={showDetail} style={{ width: '80vw' }} contentStyle={{ height: '70vw' }}
                        footer={dialogFooter}
                        onHide={() => setShowDetail(false)}>
                        <PraticaDetail data={selectedPratica.entityData} />

                    </Dialog>
                </div>
            </div>
        </div>
        </HasRole>
    );
}

MonitoraggioSLA.propTypes = {
    

};
MonitoraggioSLA.defaultProps = {

}