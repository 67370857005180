import React, {useState, useRef} from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import {Form} from "../components/Form";
import {Password} from "../components/form/Password";
import * as yup from "yup";
import axios from "axios";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'


export const PasswordRest = (props) => {
	const history = useHistory();
	var h = window.innerHeight;
	var w = window.innerWidth;
	const { search } = useLocation()
	const values = queryString.parse(search)
	const key = values.key;
	const bgStyle = {
		background : "url('https://picsum.photos/"+w+"/"+h+"/?random') no-repeat",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundPosition: "center"
	}
	const [showMessage, setShowMessage] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const toast = useRef(null);


	const schema = yup.object().shape({
		newPassword: yup.string().required("Campo Obbligatorio").min(4, 'Lunghezza minima di 4 caratteri'),
		confirmPassword: yup.string().required("Campo Obbligatorio").oneOf([yup.ref('newPassword'), null], 'Le password non coincidono'),
	});



	const onSubmit = async (data,e) => {

		setSubmitting(true);
		axios.post('/api/account/reset-password/finish',{
			key : key,
			newPassword: data.newPassword,

		}).then((res) => {
			e.target.reset();
			setShowMessage(true);
			setSubmitting(false);
		}).catch((err) => {
			setSubmitting(false);
			toast.current.show({severity:'error', summary: err.response.data.status, detail:err.response.data.detail, life: 3000});
		})



	};

	const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK"  autoFocus
																	   onClick={() => history.push("/")}/></div>;

	return (
		<div>

			<Toast ref={toast} />
			<Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter}
					showHeader={false} breakpoints={{'960px': '80vw'}} style={{width: '50vw'}}>
				<div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
					<i className="pi pi-check-circle" style={{fontSize: '5rem', color: 'var(--green-500)'}}></i>
					<h5>Impostazione Password</h5>
					<p style={{lineHeight: 1.5, textIndent: '1rem'}}>
						La password è stata impostata correttamente.
					</p>
				</div>
			</Dialog>
			{!key && <h2>Non sei autorizzato</h2>}
			{key &&
			<Form onSubmit={onSubmit} schema={schema}>
				<div className="login-body" style={bgStyle}>
					<div className="card login-panel p-fluid">
						<div className="login-panel-content">
							<div className="p-grid p-fluid">


								<div className="p-col-12 p-sm-12 p-md-12 logo-container">
									<h2>Geo4Man</h2>
									<span className="guest-sign-in">Benvenuto, <br/>imposta la password per continuare. </span>
								</div>


								<div className="p-col-12 p-md-12 p-lg-12">


									<Password withController={true} name={"newPassword"} label="Nuova Password*"/>
									<Password withController={true} name={"confirmPassword"}
											  label="Conferma Password*"/>

								</div>
							</div>
							<Button type="submit" label="Modifica Password" loading={submitting} icon="pi pi-check"/>
						</div>
					</div>
				</div>
			</Form>
			}

		</div>
	)
}