import React, {useContext, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import * as yup from "yup";
import {UserContext} from "../../hooks/UserContext";
import { Toast } from 'primereact/toast';
import useAuth from "../../hooks/useAuth";
import {Form} from "../Form";
import {Input} from "../form/Input";
import axios from "axios";


export const ProfileEditForm = () => {


    const [showMessage, setShowMessage] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const { user } = useContext(UserContext);
    const { updateUserContext } = useAuth();
    const toast = useRef(null);
    const schema = yup.object().shape({
        nome: yup.string().required("Campo Obbligatorio"),
        cognome: yup.string().required("Campo Obbligatorio"),
        email: yup.string().email("Email non valida").required("Campo Obbligatorio")
    });

   const defaultValues ={
       username: user.login,
       nome: user.firstName,
       cognome: user.lastName,
       email: user.email,
   }


    const onSubmit = async (data) => {

       setSubmitting(true);
        axios.post('/api/account',{
            firstName : data.nome,
            lastName: data.cognome,
            email: data.email,
            login : user.login,
        }).then((res) => {
            updateUserContext();
            setShowMessage(true);
            setSubmitting(false);
        }).catch((err) => {
           setSubmitting(false);
           toast.current.show({severity:'error', summary: err.response.data.status, detail:err.response.data.title, life: 3000});
       })



    };

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK"  autoFocus
                                                                       onClick={() => setShowMessage(false)}/></div>;
    return (
        <div>
            <Toast ref={toast} />
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter}
                    showHeader={false} breakpoints={{'960px': '80vw'}} style={{width: '30vw'}}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{fontSize: '5rem', color: 'var(--green-500)'}}></i>
                    <h5>Modifica Profilo</h5>
                    <p style={{lineHeight: 1.5,textAlign:"center"}}>
                        La modifica dei dati del profilo è stata effettuata correttemente.
                    </p>
                </div>
            </Dialog>
            <Form onSubmit={onSubmit} schema={schema} defaultValues={defaultValues}>

                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-12 p-lg-6">

                        <Input name="username" label="Username" withController={true} readonly value={user.login}/>
                        <Input name="nome" label="Nome *" withController={true} value={user.firstName}/>
                        <Input name="cognome" label="Cognome *" withController={true} value={user.lastName}/>
                        <Input name="email" label="Email *" withController={true} value={user.email}/>

                    </div>
                </div>
                <Button type="submit" label="Modifica Profilo" loading={submitting} icon="pi pi-check"/>
            </Form>



        </div>
    );
}
