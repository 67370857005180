import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

export const Access = () => {
	const history = useHistory();
	const style = {
		position:"relative"
	}
	return (
		<div className="exception-body accessdenied">
			<div className="exception-panel" style={style}>
				<div className="exception-content">
					<img src="/assets/layout/images/pages/icon-access.svg" alt="roma" />
					<h1>Accesso Negato</h1>
					<p>Non hai i permessi per accedere a questa sessione.</p>
					<Button label="Indietro" icon="pi pi-arrow-left" onClick={() => { history.goBack() }} />
				</div>
			</div>
		</div>
	)
}