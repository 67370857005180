import React, {useContext, useEffect, useRef, useState} from "react";
import { FileUpload } from 'primereact/fileupload';
import PropTypes from "prop-types";
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

import {AUTH_TOKEN_KEY} from "../../utils/Costants";
import {FormContext, FormItemContext} from "../../hooks/FormContext";
import {CondionalControllerWrapper} from "../../utils/CondionalControllerWrapper";
import {classNames} from "primereact/utils";
import { Panel } from 'primereact/panel';
import axios from "axios";



export const AdvancedUpload = (props) => {
    const {control} = useContext(FormContext) || {control: {}};
    return (
        <CondionalControllerWrapper name={props.name} wrap={props.withController} control={control}>
            <FormItemContext.Consumer>
                {field => <InnerAdvancedUpload {...props} />}
            </FormItemContext.Consumer>
        </CondionalControllerWrapper>
    )
};

function InnerAdvancedUpload(props) {
    const [totalSize, setTotalSize] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);

    const {errors} = useContext(FormContext) || {
        register: () => {
        }, control: {}, errors: {}
    };
    const field = useContext(FormItemContext) || {field: {}}
    const [value, setValue] = useState([]);

    useEffect(() => {

        if(field.value === undefined){
            setValue([]);
        }
    },[field.value]);
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;

        for (var i = 0; i < e.files.length; i++) {
            let file = e.files.item(i);
            _totalSize += file.size;

        }
        setTotalSize(_totalSize);
    }

    const onTemplateUpload = (e) => {
        let _totalSize = 0;
        e.files.forEach(file => {
            _totalSize += (file.size || 0);
            setTotalFiles(totalFiles + 1);
        });
        setTotalSize(_totalSize);

        let result = JSON.parse(e.xhr.response);
        let newResult = [...result, ...value];

        update(newResult);


    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }
    const onTemplateClear = () => {
        setTotalSize(0);
    }
    const update = (data) => {

        setTotalFiles(data.length);
        let dis = !props.multiple && data.length>=1;
        setDisabled(dis);
        setValue(data);
        props.onChange(data);
        if (props.withController) {
            field.onChange(data);
        }
    }

    const deleteUploadedFile = (file)=>{
        setLoading(true);
        axios.delete(props.deleteEndpoint + "/" + file.fileID, {})
            .then((res) => {
                setLoading(false);
                update(value.filter((x) => x.fileID !== file.fileID))
            }).catch((err) => {
                setLoading(false);
            })
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <span  style={{ height: '20px', marginLeft: 'auto', fontSize:"1.1em"}} >Totale: {formatedValue.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</span>

            </div>
        );
    }
    const itemTemplate = (file, props) => {
        return (
            <div className="p-d-flex p-ai-center p-flex-wrap p-p-2">
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger " onClick={() => onTemplateRemove(file, props.onRemove)} />
                <div className="p-d-flex p-ai-center" >
                    {/* <img alt={file.name} role="presentation" src={file.objectURL} width={100} /> */}
                    <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2 p-ml-auto" />
            </div>
        )
    }

    return(
        <div className="p-field">
            <label htmlFor={props.name}  className={classNames("p-d-block", {'p-error': errors[props.name]})}>{props.label}</label>
                <div className={classNames("p-component","p-dropdown",{'p-invalid': errors[props.name]})} style={{display:"block"}}>
                <FileUpload
                    ref={fileUploadRef}  multiple={props.multiple} auto={props.auto}
                    name={props.name+"[]"}  disabled={disabled}
                    maxFileSize={104857600}
                    invalidFileSizeMessageSummary="{0}: Dimensione file non valida,"
                    invalidFileSizeMessageDetail="Dimensione massima di upload: {0}"
                    url={props.endpoint}
                    onError={onTemplateClear} onClear={onTemplateClear}
                    onSelect={onTemplateSelect} onUpload={onTemplateUpload} onBeforeSend={onBeforeSend}
                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                    headerTemplate={headerTemplate}
                    itemTemplate={itemTemplate}
                    emptyTemplate={emptyTemplate}
                />

            {value &&
            <Panel header="Files caricati">
                {!value.length && <p>Nessun file caricato</p>}
                {value.map(item => {
                    return (
                        <div className="p-d-flex p-ai-center p-flex-wrap p-p-2" key={item.fileID}>
                            <Button type="button" icon="pi pi-times" onClick={() => deleteUploadedFile(item) } loading={loading}
                                    className="p-button-outlined p-button-rounded p-button-danger "/>
                            <div className="p-d-flex p-ai-center">
                                {/* <img alt={file.name} role="presentation" src={file.objectURL} width={100} /> */}
                                <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {item.filename}
                    </span>
                            </div>
                            <Tag value={item.size} severity="warning" className="p-px-3 p-py-2 p-ml-auto"/>
                        </div>
                    )
                })}
            </Panel>
            }
                </div>
            <small className="p-error" style={{display: "block"}}>{errors[props.name]?.message}</small>


        </div>

    )
}
AdvancedUpload.propTypes = {
    endpoint: PropTypes.string.isRequired,
    deleteEndpoint: PropTypes.string.isRequired,
    multiple : PropTypes.bool,
    auto: PropTypes.bool,
    name : PropTypes.string,
    label : PropTypes.string,
    onChange: PropTypes.func,
    withController: PropTypes.bool.isRequired,

}
AdvancedUpload.defaultProps = {
    endpoint : null,
    deleteEndpoint : null,
    multiple : false,
    auto: true,
    name: "files[]",
    withController: true,
    onChange: () => {
    },
}






const emptyTemplate = () => {
    return (
        <div className="p-d-flex p-ai-center p-dir-col">
            <i className="pi pi-cloud-upload p-mt-0 p-p-2" style={{'fontSize': '3em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
            <span style={{'fontSize': '1em', color: 'var(--text-color-secondary)'}} className="p-my-1">Trascina qui i file da caricare</span>
        </div>
    )
}
const chooseOptions = {icon: 'pi pi-fw pi-file', label:"Sfoglia...", iconOnly: false, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
const uploadOptions = {icon: 'pi pi-fw pi-upload', label:"Carica", iconOnly: false, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
const cancelOptions = {icon: 'pi pi-fw pi-times', label:"Annulla", iconOnly: false, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

const onBeforeSend = (e) =>{
    const token = window.localStorage.getItem(AUTH_TOKEN_KEY) ||  window.sessionStorage.getItem(AUTH_TOKEN_KEY);
    e.xhr.setRequestHeader("Authorization", `Bearer ${token}`)
}