import axios from 'axios';

export default class ContrattoService {

    getContratti(options) {
        return axios.get(`/api/contratto?size=${options.selectedPageSize}&page=${options.currentPage-1}`).then(res => res);
    }

    saveContratti() {
        return axios.get('/api/contratto/save').then(res => res.data);
    }

    getContratto(id) {
        return axios.get(`/api/contratto/${id}`).then(res => res.data);
    }

    search(query, options){
        return axios.get(`/api/contratto/search/${query}?size=${options.selectedPageSize}&page=${options.currentPage-1}`).then(res => res);
    }
}
