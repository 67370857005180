import { useState, useEffect } from 'react';
import axios from 'axios'; 

export default function useFindUser() {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() =>{
        async function findUser() {

            return await axios.get('/api/account').then(res => {

                setUser(res.data);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            })



        }
        
        findUser();  
    }, []);
    
    return {
        user,
        setUser,
        isLoading
    }
}