import React, {useEffect, useState} from 'react';
import ContrattoService from '../../service/ContrattoService';

export const DettaglioContratto = (props) => {

    const [contratto, setContratto] = useState(null);


    useEffect(() => {
        const contrattoService = new ContrattoService();
        contrattoService.getContratto(props.id).then(res => setContratto(res));
    }, []);

    return (
        <div className="card">
            <h3>CONTRATTO</h3>
            {contratto &&
            <>
                <div className="p-grid">
                    <div className="p-col-6">
                        <h4>Contratto Attuativo</h4>
                        <p>{contratto.codice}</p>
                    </div>
                    <div className="p-col-6">
                        <h4>Codice Tratta</h4>
                        <p>{contratto.codiceTratta}</p>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <h4>Descrizione</h4>
                        <p>{contratto.descrizione}</p>
                    </div>
                    <div className="p-col-6">
                        <h4>Lunghezza</h4>
                        <p>{contratto.lunghezza}m</p>
                    </div>
                    {contratto.puntoPartenza &&
                    <>
                        <div className="p-col-6">
                            <h4>Comune</h4>
                            <p>{contratto.puntoPartenza.comune}</p>
                        </div>
                        <div className="p-col-6">
                            <h4>Provincia</h4>
                            <p>{contratto.puntoPartenza.provincia}</p>
                        </div>
                        <div className="p-col-6">
                            <h4>Regione</h4>
                            <p>{contratto.puntoPartenza.regione}</p>
                        </div>
                    </>

                    }
                </div>

            </>
            }
        </div>
    );

}