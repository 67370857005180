import React from 'react';

import {HasRole} from "../../utils/HasRole";

import {BasicCrudPage} from "../../components/BasicCrudPage";
import {Input} from "../../components/form/Input";
import {Textarea} from "../../components/form/Textarea";
import * as yup from "yup";


export const TipoPraticaCrud = () => {


    const fields = {

        name : {
            label : "Nome",
            tableRenderer: null,
            schema : yup.string().required("Campo Obbligatorio"),
            formComponent : Input,
            formComponentProps : {

            }
        },
        description : {
            label : "Descrizione",
            tableRenderer: null,
            schema : yup.string(),
            formComponent : Textarea,
            formComponentProps : {

            }
        }

    }


    return (
        <HasRole roles={["ROLE_ADMIN"]} mode={"ALL"} showPanel={true}>
            <BasicCrudPage
                entityName="Tipologia Pratica"
                title="Tipologia Pratiche di manutenzione"
                subtitle="Questa pagina consente di gestire le tipologie di pratiche di manutenzione"
                endpoint="/api/TipoPratica"
                fields={fields}
                csv={false}

            />

        </HasRole>
    )
}

