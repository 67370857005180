import React, {useContext} from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import {UserContext} from "./hooks/UserContext";
import { confirmDialog } from 'primereact/confirmdialog';
import useAuth from "./hooks/useAuth";
import {getGravatar} from "./utils/Utils";


const AppTopbar = (props) => {
    const { user } = useContext(UserContext);
    const history = useHistory();
    const { logoutUser } = useAuth();

    const onTopbarItemClick = (event, item) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }
    const confirm = () => {
        confirmDialog({
            message: 'Sei sicuro di voler uscire?',
            header: 'Attenzione',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Si",
            rejectLabel:"No",
            accept: () => logoutUser(),
            reject: () => ({})
        });
    }
    const gravatar = getGravatar(user.email,100);




    return (
        <div className="layout-topbar">
           {/* <button type="button" className="p-link layout-right-panel-button layout-topbar-icon" onClick={props.onRightMenuButtonClick}>
                <i className="pi pi-ellipsis-v"></i>
            </button>*/}

            <button type="button" className="p-link layout-menu-button layout-topbar-icon" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <button type="button" className="p-link layout-topbar-logo" onClick={() => history.push('/app')}>
                <h1 className="p-text-bold" style={{color:'white'}}>GEO4MAN</h1>
            </button>



            <ul className="topbar-menu">
                <li className={classNames('user-profile', { 'active-topmenuitem fadeInDown': props.activeTopbarItem === 'profile' })}>
                    {!props.inlineUser && <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'profile')}>
                        <img src={gravatar} alt="roma-layout" className="avatar" />
                        <div className="layout-profile-userinfo">
                            <span className="layout-profile-name">{user.firstName} {user.lastName}</span>
                            <span className="layout-profile-role">{user.email}</span>
                        </div>
                    </button>}

                    <ul className="fadeInDown">
                        <li role="menuitem">
                            <button type="button" className="p-link" onClick={() => history.push('/app/user/profile')}>
                                <i className="pi pi-fw pi-user"></i>
                                <span>Profilo</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link" onClick={confirm}>
                                <i className="pi pi-fw pi-sign-out"></i>
                                <span>Logout</span>
                            </button>
                        </li>


                   {/*     <li role="menuitem">
                            <button type="button" className="p-link">
                                <i className="pi pi-fw pi-envelope"></i>
                                <span>Messages</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <i className="pi pi-fw pi-bell"></i>
                                <span>Notifications</span>
                            </button>
                        </li>*/}
                    </ul>
                </li>

                <li className={classNames({ 'active-topmenuitem fadeInDown': props.activeTopbarItem === 'settings' })}>
                  {/*  <button type="button" className="p-link layout-topbar-icon" onClick={(e) => onTopbarItemClick(e, 'settings')}>
                        <i className="topbar-icon pi pi-fw pi-bell"></i>
                    </button>*/}
                    <ul className="fadeInDown">
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="/assets/layout/images/avatar-1.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Bithika Abhedananda</span>
                                    <span className="topbar-menu-role">User interface review is done.</span>
                                </div>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="/assets/layout/images/avatar-2.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Dai Jiang</span>
                                    <span className="topbar-menu-role">Uh, we have sort of a problem here.</span>
                                </div>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="/assets/layout/images/avatar-3.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Karlien Nijhuis</span>
                                    <span className="topbar-menu-role">You apparently didn’t put the thing</span>
                                </div>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="/assets/layout/images/avatar-4.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Tom Chun</span>
                                    <span className="topbar-menu-role">Please check the files</span>
                                </div>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="/assets/layout/images/avatar-5.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Maria Trofimova</span>
                                    <span className="topbar-menu-role">Meeting reports attached.</span>
                                </div>
                            </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default AppTopbar;