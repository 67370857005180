import React from 'react';

import {HasRole} from "../../utils/HasRole";

import {BasicCrudPage} from "../../components/BasicCrudPage";
import {Input} from "../../components/form/Input";
import * as yup from "yup";
import {AjaxDropdownMultipleSelect} from "../../components/form/AjaxDropdownMultipleSelect";
import {AjaxDropdown} from "../../components/form/AjaxDropdown";



//private String login;
//private String firstName;
//private String lastName;
//private String email;
//
//private String createdBy;
//
//private Instant createdDate;
//
//private String lastModifiedBy;
//
//private Instant lastModifiedDate;
//
//private Set<String> authorities;





export const UserCrud = () => {

    const fields = {

        login : {
            label : "Username",
            tableRenderer: null,
            schema : yup.string().required("Campo Obbligatorio"),
            formComponent : Input,
            formComponentProps : {

            }
        },
        firstName : {
            label : "Nome",
            tableRenderer: null,
            schema : yup.string().required("Campo Obbligatorio"),
            formComponent : Input,
            formComponentProps : {

            }
        },
        lastName : {
            label : "Cognome",
            tableRenderer: null,
            schema : yup.string().required("Campo Obbligatorio"),
            formComponent : Input,
            formComponentProps : {
            }
        },
        email : {
            label : "Email",
            tableRenderer: null,
            schema :  yup.string().email("Email non valida").required("Campo Obbligatorio"),
            formComponent : Input,
            formComponentProps : {

            }
        },
         authorities : {
             label : "Ruoli",
             tableRenderer(rowData,table) {
                 return (
                     <React.Fragment>
                         <span className="p-column-title">Ruoli</span>
                         <ul style={{display: "inline-grid"}}>
                             {rowData.authorities.map((r, i) => {
                                 return (<li key={i}>{r.name}</li>)
                             })}
                         </ul>
                     </React.Fragment>
                 );
             },
             schema :  yup.array().min(1,"Selezionare almeno ${min} ruolo.").required("Campo Obbligatorio"),
             formComponent : AjaxDropdownMultipleSelect,

             formComponentProps : {
                 nameField: "name",
                 baseEndpoint:"/api/admin/user/roles"
             }
         },
        azienda : {
            label : "Azienda",
            tableRenderer(rowData,table) {
                return (
                    <React.Fragment>
                        <span className="p-column-title">Azienda</span>
                        {rowData.azienda ? rowData.azienda.denominazione : "-"}
                    </React.Fragment>
                );
            },
            schema :  yup.object().nullable(),
            formComponent : AjaxDropdown,

            formComponentProps : {
                nameField: "denominazione",
                baseEndpoint:"/api/azienda",
                cache:false
            }
        }



    }
    const onLoad = (data) => {

        return data.map(r=>{
            r.authorities = r.authorities.map(rs=>{return {name:rs}});
            return r;
        });

    }

    const onSubmit = (data) => {

        data.authorities = data.authorities.map(a=>{return a.name});
        return data;

    }


    return (
        <HasRole roles={["ROLE_ADMIN"]} mode={"ALL"} showPanel={true}>
            <BasicCrudPage
                entityName="Utente"
                title="Gestione Utenti"
                subtitle="Questa pagina consente di gestire gli utenti ed i permessi del sistema"
                endpoint="/api/admin/user"
                fields={fields}
                onLoad={onLoad}
                onSubmit={onSubmit}
                email={true}
                csv={false}
            />

        </HasRole>
    )
}

