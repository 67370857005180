import React, {useEffect, useState} from 'react';
import AziendaService from "../../service/AziendaService";

export const DettaglioAzienda = (props) => {

    const [azienda, setAzienda] = useState();
    
    useEffect(() => {
        const aziendaService = new AziendaService();
        aziendaService.getAzienda(props.azienda).then(res => setAzienda(res));
    }, []);

    return (
        <div className="card">
            <h3>AZIENDA APPALTATRICE</h3>
            {azienda &&
                <div className="p-grid">
                    <div className="p-col-6">
                        <h4>Codice</h4>
                        <p>{azienda.codice}</p>
                    </div>
                    <div className="p-col-6">
                        <h4>Denominazione</h4>
                        <p>{azienda.denominazione}</p>
                    </div>
                    <div className="p-col-6">
                        <h4>P.IVA</h4>
                        <p>{azienda.piva}</p>
                    </div>
                    <div className="p-col-6">
                        <h4>Indirizzo</h4>
                        <p>{azienda.indirizzo}</p>
                    </div>
                </div>
            }
        </div>
    );

}