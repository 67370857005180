import React, {useContext, useEffect, useState, Suspense, useRef} from 'react';

import {Page} from "../../components/Page";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import {ToastContext} from "../../hooks/UtilsContext";
import Loading from "../../components/Loading";
import {PraticaDetail} from "../../components/wf/PraticaDetail";
import {Button} from "primereact/button";
import { Divider } from 'primereact/divider';
import { Messages } from 'primereact/messages';
import { confirmDialog } from 'primereact/confirmdialog';
import {Dialog} from "primereact/dialog";

function loadComponent(name) {
    const Component = React.lazy(() =>
        import(`./components/${name}.js`)
    );
    return Component;
}

export const LavoraPratica = (props) => {
    const {showSuccessMessage, showWarnMessage} = useContext(ToastContext);
    const [task, setTask] = useState(null);
    const [update, setUpdate] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [component, setComponent] = useState(null);
    const [componentProps, setComponentProps] = useState({});

    const history = useHistory();
    let { taskID } = useParams();
    const ref = useRef();
    const msgs = useRef(null);
    
    useEffect(() => {
        axios.get("/api/task/" + taskID)
            .then(async (res)=>{
                
                setLoading(false);
                setTask(res.data);
                const resp = await axios.get("/api/pratica/"+taskID+"/"+res.data.entityData.id+"/loadStatus")
                var compData = resp.data.data;
                const comp = loadComponent(res.data.taskName.replace(/[^0-9a-z]/gi, ''));
                let p = {
                    task:res.data,
                    innerRef:ref,
                    savedData: compData
                 }
                setComponentProps(p)
                setComponent(comp);
            })
            .catch((err)=>{
                console.log(err);
                setLoading(false);
                showWarnMessage("Attenzione","Impossibile caricare la pratica.")
                const timer = setTimeout(() => {
                    history.push("/app");
                }, 3000);
            })

    },[taskID,update])


    useEffect(async () => {

        msgs.current?.replace(errors.map((msg)=>{
            return { severity: 'warn', summary: '', detail: msg, sticky: true };
        }));


    },[errors])

    const showErrors = () => {
        window.scrollTo(0, 0);
        if(!ref.current.isValid()){
            if(JSON.stringify(ref.current.getErrorMessages()) !== JSON.stringify(errors) ) {
                setErrors(ref.current.getErrorMessages());

            }
        }else{
            setErrors([]);
        }
        return !ref.current.isValid();
    }
    const chiudiLavorazione = () =>{

        let errors = showErrors();
        if(!errors){

            confirmDialog({
                message: 'Sicuro di voler chiudere ed inviare? Non sarà più possibile ritornare a questa attività!',
                header: 'Attenzione',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel:"Si",
                rejectLabel:"No",
                accept() {

                    setSubmitting(true);
                    ref.current.salvaEchiudi().then(() => {
                        setSubmitting(false);
                        setShowMessage(true);
                    }).catch((err) => {
                        setSubmitting(false);
                        showWarnMessage("Attenzione",err);
                    })
                },
                reject() {

                }

            });





        }


    }

    const salva = () =>{
        let errors = showErrors();
        if (!errors) {
            setSubmitting(true);
            ref.current.salva().then(() => {
                setSubmitting(false);
                setUpdate(!update);
                showSuccessMessage("Info", "Pratica salvata correttamente!");
            }).catch((err) => {
                setSubmitting(false);
                showWarnMessage("Attenzione", "Impossibile Salvare la pratica.")
            })
        }

    }

    const gotoList = () =>{
        setShowMessage(false)
        history.push("/app/pratica/list");
    };
    const gotoDetail = () =>{
        setShowMessage(false)
        history.push("/app/pratica/dettaglio/" + task.entityData.id)
    };
    const gotoHome = () =>{
        setShowMessage(false)
        history.push("/app");
    }

    const footer = (<>
        {task && (<>
            
        <Button label="Salva e invio" loading={submitting} icon="pi pi-check" className="p-button-success" style={{marginRight: '.25em', float: "right"}} onClick={chiudiLavorazione}/>
        <Button label="Salva" loading={submitting} icon="pi pi-save" className="p-button-info" style={{marginRight: '.25em', float: "right"}} onClick={salva}/>
        <Button label="Indietro" icon="pi pi-times" loading={submitting} className="p-button-secondary" onClick={()=>history.goBack()}/>
        </>)}
    </>);
    const dialogFooter = <div className="p-d-flex p-jc-center">
        <Button label="Vai alla DashBoard"  className="p-button-help" autoFocus onClick={gotoHome}/>
        <Button label="Vai ad Elenco Pratiche"  className="p-button-success" autoFocus onClick={gotoList}/>
        <Button label="Vai a Dettaglio Pratica"  className="p-button-info" autoFocus onClick={gotoDetail}/>
    </div>;
    
    return (
        <>
            {loading && (<Loading/>)}
            {!loading && (
                <Page title={"Lavorazione Pratica"} subtitle={task?.entityData?.contratto.descrizione} footer={footer} >
                    <>
                        <PraticaDetail data={task?.entityData} collapsed={true} />
                        <Messages ref={msgs} />
                        <Divider align="center">
                            <h3>Lavorazione Pratica</h3>
                        </Divider>
                        <Suspense fallback={<div>Loading...</div>}>
                            {component && React.createElement(component,componentProps)}
                        </Suspense>
                        <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter}
                                showHeader={false} breakpoints={{'960px': '80vw'}} style={{width: '80vw'}}>
                            <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                                <i className="pi pi-check-circle" style={{fontSize: '5rem', color: 'var(--green-500)'}}></i>
                                <h5>Lavorazione pratica di manutenzione</h5>
                                <p style={{lineHeight: 1.5,textAlign:"center"}}>
                                    La lavorazione è stata chiusa correttamente!
                                </p>
                            </div>
                        </Dialog>
                  </>
                </Page>
            )}
        </>
    );
}
